var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layouts',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"bg-gray-200 h-auto"},[_c('div',{staticClass:"container px-4 py-10 mx-auto max-w-7xl"},[_c('div',{staticClass:"flex items-center justify-between mb-6"},[_c('div',{staticClass:"lg:w-1/2 w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"border border-gray-400 px-3 py-2 text-sm rounded-md lg:w-1/2 w-full",attrs:{"type":"text","placeholder":"Pencarian.."},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},function($event){return _vm.tableInputSearchHandler($event)}]}})]),_c('div',{staticClass:"flex items-center"},[_c('zselect',{staticClass:"-mt-1 mr-2",staticStyle:{"width":"80px"},attrs:{"data":[5, 10, 25, 50, 80, 100, 200, 300, 500],"placeholder":"Jumlah"},on:{"input":_vm.refreshData},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('zdropdown',{attrs:{"text":"Filter Transaksi"},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('div',{staticClass:"py-6 px-6"},[_c('h4',{staticClass:"text-sm font-medium mr-2 mb-1"},[_vm._v(" Rentang Tanggal: ")]),_c('zselect',{staticClass:"mb-5",attrs:{"data":[
                      'Hari Ini',
                      'Bulan Ini',
                      'Tahun Ini',
                      '7 Hari Terakhir',
                      '30 Hari Terakhir' ],"placeholder":"Pilih Periode"},on:{"input":_vm.selectRangeDate},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}}),_c('h1',{staticClass:"font-medium mb-5"},[_vm._v("atau")]),_c('div',{staticClass:"flex items-center space-x-2"},[_c('div',{staticClass:"w-1/2"},[_c('h4',{staticClass:"text-sm font-medium mr-2 mb-1"},[_vm._v(" Tanggal Mulai: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.start_date),expression:"start_date"}],staticClass:"w-full mr-5 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-1.5",attrs:{"type":"date"},domProps:{"value":(_vm.start_date)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.start_date=$event.target.value},_vm.refreshData]}})]),_c('div',{staticClass:"w-1/2"},[_c('h4',{staticClass:"text-sm font-medium mr-2 mb-1"},[_vm._v(" Tanggal Akhir: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.end_date),expression:"end_date"}],staticClass:"w-full rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-800 text-sm px-3 py-1.5",attrs:{"type":"date"},domProps:{"value":(_vm.end_date)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.end_date=$event.target.value},_vm.refreshData]}})])]),_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"mt-5 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-semibold text-white rounded-md shadow",on:{"click":function($event){_vm.search = '';
                        _vm.filterDate = '';
                        _vm.start_date = '';
                        _vm.end_date = '';
                        _vm.refreshData();}}},[_vm._v(" Reset Filter ")])])],1)]},proxy:true}])})],1)]),_c('datatable',{attrs:{"data":_vm.data.data,"columns":_vm.columns,"meta":_vm.data.meta,"PageNavHandler":_vm.pageNavHandler,"TableInputSearchHandler":_vm.tableInputSearchHandler},scopedSlots:_vm._u([{key:"createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.createdAt))+" ")]}},{key:"action",fn:function(props){return [_c('div',{staticClass:"flex items-center"},[_c('zdropdown',{attrs:{"text":"Print Resi"},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('div',{staticClass:"max-w-sm"},[_c('a',{staticClass:"cursor-pointer hover:text-gray-700 px-3 py-2 text-sm text-gray-600 focus:outline-none flex items-center mr-2",staticStyle:{"font-size":"11px"},on:{"click":function($event){return _vm.openNewTab(
                          '/print-agen/resi/' + props.item.id + '?type=IDR'
                        )}}},[_c('svg',{staticClass:"w-4 h-4 mr-2 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z","clip-rule":"evenodd"}})]),_c('h4',{staticClass:"font-semibold"},[_vm._v("Mata Uang IDR")])]),_c('hr'),_c('a',{staticClass:"cursor-pointer hover:text-gray-700 px-3 py-2 text-sm text-gray-600 focus:outline-none flex items-center mr-2",staticStyle:{"font-size":"11px"},on:{"click":function($event){return _vm.openNewTab(
                          '/print-agen/resi/' + props.item.id + '?type=USD'
                        )}}},[_c('svg',{staticClass:"w-4 h-4 mr-2 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z","clip-rule":"evenodd"}})]),_c('h4',{staticClass:"font-semibold"},[_vm._v("Mata Uang USD")])])])]},proxy:true}],null,true)})],1)]}}])})],1),_c('zloading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }