const countries = [
  {
    country: "Afghanistan",
    country_code: 93,
    iso_code: "AF",
    alias: "AFG",
  },
  {
    country: "Albania",
    country_code: 355,
    iso_code: "AL",
    alias: "ALB",
  },
  {
    country: "Algeria",
    country_code: 213,
    iso_code: "DZ",
    alias: "DZA",
  },
  {
    country: "American Samoa",
    country_code: "1-684",
    iso_code: "AS",
    alias: "ASM",
  },
  {
    country: "Andorra",
    country_code: 376,
    iso_code: "AD",
    alias: "AND",
  },
  {
    country: "Angola",
    country_code: 244,
    iso_code: "AO",
    alias: "AGO",
  },
  {
    country: "Anguilla",
    country_code: "1-264",
    iso_code: "AI",
    alias: "AIA",
  },
  {
    country: "Antarctica",
    country_code: 672,
    iso_code: "AQ",
    alias: "ATA",
  },
  {
    country: "Antigua and Barbuda",
    country_code: "1-268",
    iso_code: "AG",
    alias: "ATG",
  },
  {
    country: "Argentina",
    country_code: 54,
    iso_code: "AR",
    alias: "AGR",
  },
  {
    country: "Armenia",
    country_code: 374,
    iso_code: "AM",
    alias: "ARM",
  },
  {
    country: "Aruba",
    country_code: 297,
    iso_code: "AW",
    alias: "ABW",
  },
  {
    country: "Australia",
    country_code: 61,
    iso_code: "AU",
    alias: "AUS",
  },
  {
    country: "Austria",
    country_code: 43,
    iso_code: "AT",
    alias: "AUT",
  },
  {
    country: "Azerbaijan",
    country_code: 994,
    iso_code: "AZ",
    alias: "AZE",
  },
  {
    country: "Bahamas",
    country_code: "1-242",
    iso_code: "BS",
    alias: "BHS",
  },
  {
    country: "Bahrain",
    country_code: 973,
    iso_code: "BH",
    alias: "BHR",
  },
  {
    country: "Bangladesh",
    country_code: 880,
    iso_code: "BD",
    alias: "BGD",
  },
  {
    country: "Barbados",
    country_code: "1-246",
    iso_code: "BB",
    alias: "BRB",
  },
  {
    country: "Belarus",
    country_code: 375,
    iso_code: "BY",
    alias: "BLR",
  },
  {
    country: "Belgium",
    country_code: 32,
    iso_code: "BE",
    alias: "BEL",
  },
  {
    country: "Belize",
    country_code: 501,
    iso_code: "BZ",
    alias: "BLZ",
  },
  {
    country: "Benin",
    country_code: 229,
    iso_code: "BJ",
    alias: "BEN",
  },
  {
    country: "Bermuda",
    country_code: "1-441",
    iso_code: "BM",
    alias: "BMU",
  },
  {
    country: "Bhutan",
    country_code: 975,
    iso_code: "BT",
    alias: "BTN",
  },
  {
    country: "Bolivia",
    country_code: 591,
    iso_code: "BO",
    alias: "BOL",
  },
  {
    country: "Bosnia and Herzegovina",
    country_code: 387,
    iso_code: "BA",
    alias: "BIH",
  },
  {
    country: "Botswana",
    country_code: 267,
    iso_code: "BW",
    alias: "BWA",
  },
  {
    country: "Brazil",
    country_code: 55,
    iso_code: "BR",
    alias: "BRA",
  },
  {
    country: "British Indian Ocean Territory",
    country_code: 246,
    iso_code: "IO",
    alias: "IOT",
  },
  {
    country: "British Virgin Islands",
    country_code: "1-284",
    iso_code: "VG",
    alias: "VGB",
  },
  {
    country: "Brunei",
    country_code: 673,
    iso_code: "BN",
    alias: "BRN",
  },
  {
    country: "Bulgaria",
    country_code: 359,
    iso_code: "BG",
    alias: "BGR",
  },
  {
    country: "Burkina Faso",
    country_code: 226,
    iso_code: "BF",
    alias: "BFA",
  },
  {
    country: "Burundi",
    country_code: 257,
    iso_code: "BI",
    alias: "BDI",
  },
  {
    country: "Cambodia",
    country_code: 855,
    iso_code: "KH",
    alias: "KHM",
  },
  {
    country: "Cameroon",
    country_code: 237,
    iso_code: "CM",
    alias: "CMR",
  },
  {
    country: "Canada",
    country_code: 1,
    iso_code: "CA",
    alias: "CAN",
  },
  {
    country: "Cape Verde",
    country_code: 238,
    iso_code: "CV",
    alias: "CPV",
  },
  {
    country: "Cayman Islands",
    country_code: "1-345",
    iso_code: "KY",
    alias: "CYM",
  },
  {
    country: "Central African Republic",
    country_code: 236,
    iso_code: "CF",
    alias: "CAF",
  },
  {
    country: "Chad",
    country_code: 235,
    iso_code: "TD",
    alias: "TCD",
  },
  {
    country: "Chile",
    country_code: 56,
    iso_code: "CL",
    alias: "CHL",
  },
  {
    country: "China",
    country_code: 86,
    iso_code: "CN",
    alias: "CHN",
  },
  {
    country: "Christmas Island",
    country_code: 61,
    iso_code: "CX",
    alias: "CXR",
  },
  {
    country: "Cocos Islands",
    country_code: 61,
    iso_code: "CC",
    alias: "CCK",
  },
  {
    country: "Colombia",
    country_code: 57,
    iso_code: "CO",
    alias: "COL",
  },
  {
    country: "Comoros",
    country_code: 269,
    iso_code: "KM",
    alias: "COM",
  },
  {
    country: "Cook Islands",
    country_code: 682,
    iso_code: "CK",
    alias: "COK",
  },
  {
    country: "Costa Rica",
    country_code: 506,
    iso_code: "CR",
    alias: "CRI",
  },
  {
    country: "Croatia",
    country_code: 385,
    iso_code: "HR",
    alias: "HRV",
  },
  {
    country: "Cuba",
    country_code: 53,
    iso_code: "CU",
    alias: "CUB",
  },
  {
    country: "Curacao",
    country_code: 599,
    iso_code: "CW",
    alias: "CWC",
  },
  {
    country: "Cyprus",
    country_code: 357,
    iso_code: "CY",
    alias: "CYP",
  },
  {
    country: "Czech Republic",
    country_code: 420,
    iso_code: "CZ",
    alias: "CZE",
  },
  {
    country: "Democratic Republic of the Congo",
    country_code: 243,
    iso_code: "CD",
    alias: "CDR",
  },
  {
    country: "Denmark",
    country_code: 45,
    iso_code: "DK",
    alias: "DNK",
  },
  {
    country: "Djibouti",
    country_code: 253,
    iso_code: "DJ",
    alias: "DJI",
  },
  {
    country: "Dominica",
    country_code: "1-767",
    iso_code: "DM",
    alias: "DMA",
  },
  {
    country: "Dominican Republic",
    country_code: "1-809, 1-829, 1-849",
    iso_code: "DO",
    alias: "DOM",
  },
  {
    country: "East Timor",
    country_code: 670,
    iso_code: "TL",
    alias: "TLT",
  },
  {
    country: "Ecuador",
    country_code: 593,
    iso_code: "EC",
    alias: "ECU",
  },
  {
    country: "Egypt",
    country_code: 20,
    iso_code: "EG",
    alias: "EGY",
  },
  {
    country: "El Salvador",
    country_code: 503,
    iso_code: "SV",
    alias: "SLV",
  },
  {
    country: "Equatorial Guinea",
    country_code: 240,
    iso_code: "GQ",
    alias: "GNQ",
  },
  {
    country: "Eritrea",
    country_code: 291,
    iso_code: "ER",
    alias: "ERI",
  },
  {
    country: "Estonia",
    country_code: 372,
    iso_code: "EE",
    alias: "EST",
  },
  {
    country: "Ethiopia",
    country_code: 251,
    iso_code: "ET",
    alias: "ETH",
  },
  {
    country: "Falkland Islands",
    country_code: 500,
    iso_code: "FK",
    alias: "FLK",
  },
  {
    country: "Faroe Islands",
    country_code: 298,
    iso_code: "FO",
    alias: "FRO",
  },
  {
    country: "Fiji",
    country_code: 679,
    iso_code: "FJ",
    alias: "FJI",
  },
  {
    country: "Finland",
    country_code: 358,
    iso_code: "FI",
    alias: "FIN",
  },
  {
    country: "France",
    country_code: 33,
    iso_code: "FR",
    alias: "FRA",
  },
  {
    country: "French Polynesia",
    country_code: 689,
    iso_code: "PF",
    alias: "PYF",
  },
  {
    country: "Gabon",
    country_code: 241,
    iso_code: "GA",
    alias: "GAB",
  },
  {
    country: "Gambia",
    country_code: 220,
    iso_code: "GM",
    alias: "GMB",
  },
  {
    country: "Georgia",
    country_code: 995,
    iso_code: "GE",
    alias: "GEO",
  },
  {
    country: "Germany",
    country_code: 49,
    iso_code: "DE",
    alias: "DEU",
  },
  {
    country: "Ghana",
    country_code: 233,
    iso_code: "GH",
    alias: "GHA",
  },
  {
    country: "Gibraltar",
    country_code: 350,
    iso_code: "GI",
    alias: "GIB",
  },
  {
    country: "Greece",
    country_code: 30,
    iso_code: "GR",
    alias: "GRC",
  },
  {
    country: "Greenland",
    country_code: 299,
    iso_code: "GL",
    alias: "GRL",
  },
  {
    country: "Grenada",
    country_code: "1-473",
    iso_code: "GD",
    alias: "GRD",
  },
  {
    country: "Guam",
    country_code: "1-671",
    iso_code: "GU",
    alias: "GUM",
  },
  {
    country: "Guatemala",
    country_code: 502,
    iso_code: "GT",
    alias: "GTM",
  },
  {
    country: "Guernsey",
    country_code: "44-1481",
    iso_code: "GG",
    alias: "GGY",
  },
  {
    country: "Guinea",
    country_code: 224,
    iso_code: "GN",
    alias: "GIN",
  },
  {
    country: "Guinea-Bissau",
    country_code: 245,
    iso_code: "GW",
    alias: "GNB",
  },
  {
    country: "Guyana",
    country_code: 592,
    iso_code: "GY",
    alias: "GUY",
  },
  {
    country: "Haiti",
    country_code: 509,
    iso_code: "HT",
    alias: "HTI",
  },
  {
    country: "Honduras",
    country_code: 504,
    iso_code: "HN",
    alias: "HND",
  },
  {
    country: "Hong Kong",
    country_code: 852,
    iso_code: "HK",
    alias: "HKG",
  },
  {
    country: "Hungary",
    country_code: 36,
    iso_code: "HU",
    alias: "HUN",
  },
  {
    country: "Iceland",
    country_code: 354,
    iso_code: "IS",
    alias: "ISL",
  },
  {
    country: "India",
    country_code: 91,
    iso_code: "IN",
    alias: "IND",
  },
  {
    country: "Indonesia",
    country_code: 62,
    iso_code: "ID",
    alias: "IDN",
  },
  {
    country: "Iran",
    country_code: 98,
    iso_code: "IR",
    alias: "IRN",
  },
  {
    country: "Iraq",
    country_code: 964,
    iso_code: "IQ",
    alias: "IRQ",
  },
  {
    country: "Ireland",
    country_code: 353,
    iso_code: "IE",
    alias: "IRL",
  },
  {
    country: "Isle of Man",
    country_code: "44-1624",
    iso_code: "IM",
    alias: "IMN",
  },
  {
    country: "Israel",
    country_code: 972,
    iso_code: "IL",
    alias: "ISR",
  },
  {
    country: "Italy",
    country_code: 39,
    iso_code: "IT",
    alias: "ITA",
  },
  {
    country: "Ivory Coast",
    country_code: 225,
    iso_code: "CI",
    alias: "CIR",
  },
  {
    country: "Jamaica",
    country_code: "1-876",
    iso_code: "JM",
    alias: "JAM",
  },
  {
    country: "Japan",
    country_code: 81,
    iso_code: "JP",
    alias: "JPN",
  },
  {
    country: "Jersey",
    country_code: "44-1534",
    iso_code: "JE",
    alias: "JEY",
  },
  {
    country: "Jordan",
    country_code: 962,
    iso_code: "JO",
    alias: "JOR",
  },
  {
    country: "Kazakhstan",
    country_code: 7,
    iso_code: "KZ",
    alias: "KAZ",
  },
  {
    country: "Kenya",
    country_code: 254,
    iso_code: "KE",
    alias: "KEN",
  },
  {
    country: "Kiribati",
    country_code: 686,
    iso_code: "KI",
    alias: "KIR",
  },
  {
    country: "Kosovo",
    country_code: 383,
    iso_code: "XK",
    alias: "XKV",
  },
  {
    country: "Kuwait",
    country_code: 965,
    iso_code: "KW",
    alias: "KWT",
  },
  {
    country: "Kyrgyzstan",
    country_code: 996,
    iso_code: "KG",
    alias: "KGZ",
  },
  {
    country: "Laos",
    country_code: 856,
    iso_code: "LA",
    alias: "LAO",
  },
  {
    country: "Latvia",
    country_code: 371,
    iso_code: "LV",
    alias: "LVA",
  },
  {
    country: "Lebanon",
    country_code: 961,
    iso_code: "LB",
    alias: "LBN",
  },
  {
    country: "Lesotho",
    country_code: 266,
    iso_code: "LS",
    alias: "LSO",
  },
  {
    country: "Liberia",
    country_code: 231,
    iso_code: "LR",
    alias: "LBR",
  },
  {
    country: "Libya",
    country_code: 218,
    iso_code: "LY",
    alias: "LBY",
  },
  {
    country: "Liechtenstein",
    country_code: 423,
    iso_code: "LI",
    alias: "LIE",
  },
  {
    country: "Lithuania",
    country_code: 370,
    iso_code: "LT",
    alias: "LTU",
  },
  {
    country: "Luxembourg",
    country_code: 352,
    iso_code: "LU",
    alias: "LUX",
  },
  {
    country: "Macau",
    country_code: 853,
    iso_code: "MO",
    alias: "MAO",
  },
  {
    country: "Macedonia",
    country_code: 389,
    iso_code: "MK",
    alias: "MKD",
  },
  {
    country: "Madagascar",
    country_code: 261,
    iso_code: "MG",
    alias: "MDG",
  },
  {
    country: "Malawi",
    country_code: 265,
    iso_code: "MW",
    alias: "MWI",
  },
  {
    country: "Malaysia",
    country_code: 60,
    iso_code: "MY",
    alias: "MYS",
  },
  {
    country: "East Malaysia",
    country_code: 60,
    iso_code: "MY",
    alias: "EMYS",
  },
  {
    country: "West Malaysia",
    country_code: 60,
    iso_code: "MY",
    alias: "WMYS",
  },
  {
    country: "Maldives",
    country_code: 960,
    iso_code: "MV",
    alias: "MDV",
  },
  {
    country: "Mali",
    country_code: 223,
    iso_code: "ML",
    alias: "MLI",
  },
  {
    country: "Malta",
    country_code: 356,
    iso_code: "MT",
    alias: "MLT",
  },
  {
    country: "Marshall Islands",
    country_code: 692,
    iso_code: "MH",
    alias: "MHL",
  },
  {
    country: "Mauritania",
    country_code: 222,
    iso_code: "MR",
    alias: "MRT",
  },
  {
    country: "Mauritius",
    country_code: 230,
    iso_code: "MU",
    alias: "MUS",
  },
  {
    country: "Mayotte",
    country_code: 262,
    iso_code: "YT",
    alias: "MYT",
  },
  {
    country: "Mexico",
    country_code: 52,
    iso_code: "MX",
    alias: "MEX",
  },
  {
    country: "Micronesia",
    country_code: 691,
    iso_code: "FM",
    alias: "FSM",
  },
  {
    country: "Moldova",
    country_code: 373,
    iso_code: "MD",
    alias: "MDA",
  },
  {
    country: "Monaco",
    country_code: 377,
    iso_code: "MC",
    alias: "MCO",
  },
  {
    country: "Mongolia",
    country_code: 976,
    iso_code: "MN",
    alias: "MNG",
  },
  {
    country: "Montenegro",
    country_code: 382,
    iso_code: "ME",
    alias: "MNE",
  },
  {
    country: "Montserrat",
    country_code: "1-664",
    iso_code: "MS",
    alias: "MSR",
  },
  {
    country: "Morocco",
    country_code: 212,
    iso_code: "MA",
    alias: "MAR",
  },
  {
    country: "Mozambique",
    country_code: 258,
    iso_code: "MZ",
    alias: "MOZ",
  },
  {
    country: "Myanmar",
    country_code: 95,
    iso_code: "MM",
    alias: "MMR",
  },
  {
    country: "Namibia",
    country_code: 264,
    iso_code: "NA",
    alias: "NAM",
  },
  {
    country: "Nauru",
    country_code: 674,
    iso_code: "NR",
    alias: "NRU",
  },
  {
    country: "Nepal",
    country_code: 977,
    iso_code: "NP",
    alias: "NPL",
  },
  {
    country: "Netherlands",
    country_code: 31,
    iso_code: "NL",
    alias: "NLD",
  },
  {
    country: "Netherlands Antilles",
    country_code: 599,
    iso_code: "AN",
    alias: "ANT",
  },
  {
    country: "New Caledonia",
    country_code: 687,
    iso_code: "NC",
    alias: "NCL",
  },
  {
    country: "New Zealand",
    country_code: 64,
    iso_code: "NZ",
    alias: "NZL",
  },
  {
    country: "Nicaragua",
    country_code: 505,
    iso_code: "NI",
    alias: "NIC",
  },
  {
    country: "Niger",
    country_code: 227,
    iso_code: "NE",
    alias: "NER",
  },
  {
    country: "Nigeria",
    country_code: 234,
    iso_code: "NG",
    alias: "NGA",
  },
  {
    country: "Niue",
    country_code: 683,
    iso_code: "NU",
    alias: "NIU",
  },
  {
    country: "North Korea",
    country_code: 850,
    iso_code: "KP",
    alias: "PKR",
  },
  {
    country: "Northern Mariana Islands",
    country_code: "1-670",
    iso_code: "MP",
    alias: "MNP",
  },
  {
    country: "Norway",
    country_code: 47,
    iso_code: "NO",
    alias: "NOR",
  },
  {
    country: "Oman",
    country_code: 968,
    iso_code: "OM",
    alias: "OMN",
  },
  {
    country: "Pakistan",
    country_code: 92,
    iso_code: "PK",
    alias: "PAK",
  },
  {
    country: "Palau",
    country_code: 680,
    iso_code: "PW",
    alias: "PLW",
  },
  {
    country: "Palestine",
    country_code: 970,
    iso_code: "PS",
    alias: "PSE",
  },
  {
    country: "Panama",
    country_code: 507,
    iso_code: "PA",
    alias: "PAN",
  },
  {
    country: "Papua New Guinea",
    country_code: 675,
    iso_code: "PG",
    alias: "PNG",
  },
  {
    country: "Paraguay",
    country_code: 595,
    iso_code: "PY",
    alias: "PRY",
  },
  {
    country: "Peru",
    country_code: 51,
    iso_code: "PE",
    alias: "PER",
  },
  {
    country: "Philippines",
    country_code: 63,
    iso_code: "PH",
    alias: "PHL",
  },
  {
    country: "Pitcairn",
    country_code: 64,
    iso_code: "PN",
    alias: "PCN",
  },
  {
    country: "Poland",
    country_code: 48,
    iso_code: "PL",
    alias: "POL",
  },
  {
    country: "Portugal",
    country_code: 351,
    iso_code: "PT",
    alias: "PRT",
  },
  {
    country: "Puerto Rico",
    country_code: "1-787, 1-939",
    iso_code: "PR",
    alias: "PRI",
  },
  {
    country: "Qatar",
    country_code: 974,
    iso_code: "QA",
    alias: "QAT",
  },
  {
    country: "Republic of the Congo",
    country_code: 242,
    iso_code: "CG",
    alias: "COG",
  },
  {
    country: "Reunion",
    country_code: 262,
    iso_code: "RE",
    alias: "REU",
  },
  {
    country: "Romania",
    country_code: 40,
    iso_code: "RO",
    alias: "ROU",
  },
  {
    country: "Russia",
    country_code: 7,
    iso_code: "RU",
    alias: "RUS",
  },
  {
    country: "Rwanda",
    country_code: 250,
    iso_code: "RW",
    alias: "RWA",
  },
  {
    country: "Saint Barthelemy",
    country_code: 590,
    iso_code: "BL",
    alias: "BLM",
  },
  {
    country: "Saint Helena",
    country_code: 290,
    iso_code: "SH",
    alias: "SHN",
  },
  {
    country: "Saint Kitts and Nevis",
    country_code: "1-869",
    iso_code: "KN",
    alias: "KNA",
  },
  {
    country: "Saint Lucia",
    country_code: "1-758",
    iso_code: "LC",
    alias: "LCA",
  },
  {
    country: "Saint Martin",
    country_code: 590,
    iso_code: "MF",
    alias: "MAF",
  },
  {
    country: "Saint Pierre and Miquelon",
    country_code: 508,
    iso_code: "PM",
    alias: "SPM",
  },
  {
    country: "Saint Vincent and the Grenadines",
    country_code: "1-784",
    iso_code: "VC",
    alias: "VCT",
  },
  {
    country: "Samoa",
    country_code: 685,
    iso_code: "WS",
    alias: "WSM",
  },
  {
    country: "San Marino",
    country_code: 378,
    iso_code: "SM",
    alias: "SMR",
  },
  {
    country: "Sao Tome and Principe",
    country_code: 239,
    iso_code: "ST",
    alias: "STP",
  },
  {
    country: "Saudi Arabia",
    country_code: 966,
    iso_code: "SA",
    alias: "SAU",
  },
  {
    country: "Senegal",
    country_code: 221,
    iso_code: "SN",
    alias: "SEN",
  },
  {
    country: "Serbia",
    country_code: 381,
    iso_code: "RS",
    alias: "SRB",
  },
  {
    country: "Seychelles",
    country_code: 248,
    iso_code: "SC",
    alias: "SYC",
  },
  {
    country: "Sierra Leone",
    country_code: 232,
    iso_code: "SL",
    alias: "SLE",
  },
  {
    country: "Singapore",
    country_code: 65,
    iso_code: "SG",
    alias: "SGP",
  },
  {
    country: "Sint Maarten",
    country_code: "1-721",
    iso_code: "SX",
    alias: "SXM",
  },
  {
    country: "Slovakia",
    country_code: 421,
    iso_code: "SK",
    alias: "SVK",
  },
  {
    country: "Slovenia",
    country_code: 386,
    iso_code: "SI",
    alias: "SVN",
  },
  {
    country: "Solomon Islands",
    country_code: 677,
    iso_code: "SB",
    alias: "SLB",
  },
  {
    country: "Somalia",
    country_code: 252,
    iso_code: "SO",
    alias: "SOM",
  },
  {
    country: "South Africa",
    country_code: 27,
    iso_code: "ZA",
    alias: "ZAF",
  },
  {
    country: "South Korea",
    country_code: 82,
    iso_code: "KR",
    alias: "KOR",
  },
  {
    country: "South Sudan",
    country_code: 211,
    iso_code: "SS",
    alias: "SSD",
  },
  {
    country: "Spain",
    country_code: 34,
    iso_code: "ES",
    alias: "ESP",
  },
  {
    country: "Sri Lanka",
    country_code: 94,
    iso_code: "LK",
    alias: "LKA",
  },
  {
    country: "Sudan",
    country_code: 249,
    iso_code: "SD",
    alias: "SDN",
  },
  {
    country: "Suriname",
    country_code: 597,
    iso_code: "SR",
    alias: "SUR",
  },
  {
    country: "Svalbard and Jan Mayen",
    country_code: 47,
    iso_code: "SJ",
    alias: "SJM",
  },
  {
    country: "Swaziland",
    country_code: 268,
    iso_code: "SZ",
    alias: "SWZ",
  },
  {
    country: "Sweden",
    country_code: 46,
    iso_code: "SE",
    alias: "SWE",
  },
  {
    country: "Switzerland",
    country_code: 41,
    iso_code: "CH",
    alias: "CHE",
  },
  {
    country: "Syria",
    country_code: 963,
    iso_code: "SY",
    alias: "SYR",
  },
  {
    country: "Taiwan",
    country_code: 886,
    iso_code: "TW",
    alias: "TWN",
  },
  {
    country: "Tajikistan",
    country_code: 992,
    iso_code: "TJ",
    alias: "TJK",
  },
  {
    country: "Tanzania",
    country_code: 255,
    iso_code: "TZ",
    alias: "TZA",
  },
  {
    country: "Thailand",
    country_code: 66,
    iso_code: "TH",
    alias: "THA",
  },
  {
    country: "Togo",
    country_code: 228,
    iso_code: "TG",
    alias: "TGO",
  },
  {
    country: "Tokelau",
    country_code: 690,
    iso_code: "TK",
    alias: "TKL",
  },
  {
    country: "Tonga",
    country_code: 676,
    iso_code: "TO",
    alias: "TON",
  },
  {
    country: "Trinidad and Tobago",
    country_code: "1-868",
    iso_code: "TT",
    alias: "TTO",
  },
  {
    country: "Tunisia",
    country_code: 216,
    iso_code: "TN",
    alias: "TUN",
  },
  {
    country: "Turkey",
    country_code: 90,
    iso_code: "TR",
    alias: "TUR",
  },
  {
    country: "Turkmenistan",
    country_code: 993,
    iso_code: "TM",
    alias: "TKM",
  },
  {
    country: "Turks and Caicos Islands",
    country_code: "1-649",
    iso_code: "TC",
    alias: "TCA",
  },
  {
    country: "Tuvalu",
    country_code: 688,
    iso_code: "TV",
    alias: "TUV",
  },
  {
    country: "U.S. Virgin Islands",
    country_code: "1-340",
    iso_code: "VI",
    alias: "VIR",
  },
  {
    country: "Uganda",
    country_code: 256,
    iso_code: "UG",
    alias: "UGA",
  },
  {
    country: "Ukraine",
    country_code: 380,
    iso_code: "UA",
    alias: "UKR",
  },
  {
    country: "United Arab Emirates",
    country_code: 971,
    iso_code: "AE",
    alias: "ARE",
  },
  {
    country: "United Kingdom",
    country_code: 44,
    iso_code: "GB",
    alias: "GBR",
  },
  {
    country: "United States",
    country_code: 1,
    iso_code: "US",
    alias: "USA",
  },
  {
    country: "Uruguay",
    country_code: 598,
    iso_code: "UY",
    alias: "URY",
  },
  {
    country: "Uzbekistan",
    country_code: 998,
    iso_code: "UZ",
    alias: "UZB",
  },
  {
    country: "Vanuatu",
    country_code: 678,
    iso_code: "VU",
    alias: "VUT",
  },
  {
    country: "Vatican",
    country_code: 379,
    iso_code: "VA",
    alias: "VAT",
  },
  {
    country: "Venezuela",
    country_code: 58,
    iso_code: "VE",
    alias: "VEN",
  },
  {
    country: "Vietnam",
    country_code: 84,
    iso_code: "VN",
    alias: "VEN",
  },
  {
    country: "Wallis and Futuna",
    country_code: 681,
    iso_code: "WF",
    alias: "WLF",
  },
  {
    country: "Western Sahara",
    country_code: 212,
    iso_code: "EH",
    alias: "ESH",
  },
  {
    country: "Yemen",
    country_code: 967,
    iso_code: "YE",
    alias: "YEM",
  },
  {
    country: "Zambia",
    country_code: 260,
    iso_code: "ZM",
    alias: "ZMB",
  },
  {
    country: "Zimbabwe",
    country_code: 263,
    iso_code: "ZW",
    alias: "ZWE",
  },
];

export default countries;
