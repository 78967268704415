<template>
  <div id="PROFOMA_INVOICE">
    <svg class="Rectangle_15">
      <rect
        fill="rgba(255,255,255,1)"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_15"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="563"
        height="217"
      ></rect>
    </svg>
    <svg class="Line_19" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_19"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_25" viewBox="0 0 1 216">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_25"
        d="M 0 0 L 0 216"
      ></path>
    </svg>
    <div id="Name________________y">
      <span>Name </span>
    </div>
    <div id="Address_z">
      <span>Address</span>
    </div>
    <svg class="Line_28" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_28"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_30" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_30"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <div id="SITI_HAJAR_OMAR">
      <!-- <span><?php echo $nama_penerima;?></span> -->
      {{ data.recipient_name }}
    </div>
    <div id="ID36__JALAN_LANAI_KIARA__KIARA_">
      <!-- <span><?php echo wordwrap($alamat_penerima,50, "<br>\n" );?></span> -->
      {{ data.recipient_address }}
    </div>
    <div id="Telp_">
      <span>Telp</span>
    </div>
    <div id="ID_No__">
      <span>ID No.</span>
    </div>
    <div id="_60123729577_">
      <!-- <span><?php echo $telp_penerima?></span> -->
      {{ data.recipient_phone }}
    </div>
    <div id="CONSIGNEE">
      <span>CONSIGNEE</span>
    </div>
    <svg class="Rectangle_16">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_16"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="563"
        height="217"
      ></rect>
    </svg>
    <svg class="Line_31" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_31"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_32" viewBox="0 0 1 216">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_32"
        d="M 0 0 L 0 216"
      ></path>
    </svg>
    <div id="Name________________y">
      <span>Name </span>
    </div>
    <div id="Address_z">
      <span>Address</span>
    </div>
    <svg class="Line_33" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_33"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_34" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_34"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <div id="ID36__JALAN_LANAI_KIARA__KIARA_">
      <!-- <span><?php echo wordwrap($alamat_penerima,50, "<br>\n" );?></span> -->
      {{ data.recipient_address }}
    </div>
    <div id="Telp_">
      <span>Telp</span>
    </div>
    <div id="ID_No__">
      <span>ID No.</span>
    </div>
    <div id="_60123729577_">
      <!-- <span><?php echo $telp_penerima;?></span> -->
      {{ data.recipient_phone }}
    </div>
    <div id="CONSIGNEE_">
      <span>CONSIGNEE</span>
    </div>
    <svg class="Line_35" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_35"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_36" viewBox="0 0 1 187">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_36"
        d="M 0 0 L 0 187"
      ></path>
    </svg>
    <div id="Name________________bi">
      <span>Name </span>
    </div>
    <div id="Address_bj">
      <span>Address</span>
    </div>
    <svg class="Line_37" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_37"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_38" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_38"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <div id="Telp_bo">
      <span>Telp</span>
    </div>
    <div id="SHIPPER">
      <span>SHIPPER</span>
    </div>
    <svg class="Rectangle_18">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_18"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="563"
        height="190"
      ></rect>
    </svg>
    <svg class="Line_39" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_39"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_40" viewBox="0 0 1 189">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_40"
        d="M 0 0 L 0 189"
      ></path>
    </svg>
    <div id="Name________________bi">
      <span>Name </span>
    </div>
    <div id="Address_bj">
      <span>Address</span>
    </div>
    <svg class="Line_41" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_41"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <svg class="Line_42" viewBox="0 0 562.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_42"
        d="M 0 0 L 562.5 0"
      ></path>
    </svg>
    <div id="AIRA_B___MAMA_AZMI">
      <!-- <span><?php echo $nama_pengirim;?></span> -->
      {{ data.sender_name }}
    </div>
    <div id="Cirebon___West_Java___Indonesi">
      <!-- <span><?php echo wordwrap($alamat_pengirim,50, "<br>", true );?></span> -->
      {{ data.sender_address }}
    </div>
    <div id="Telp_bo">
      <span>Telp</span>
    </div>
    <div id="ID081222111346">
      <!-- <span><?php echo $telp_pengirim?></span> -->
      {{ data.sender_phone }}
    </div>
    <div id="COMMERCIAL_INVOICE">
      <span>COMMERCIAL INVOICE</span>
    </div>
    <div id="Repeat_Grid_3">
      <div id="Repeat_Grid_3_0">
        <div id="AWB_No_____">
          <span>AWB No </span>
        </div>
      </div>
    </div>
    <div id="__31532637802_bv">
      <!-- <span>: <?php echo $awb_no;?></span> -->
      : {{ data.awb_no }}
    </div>
    <div id="__31532637802_bv">
      <span></span>
    </div>
    <div id="Group_2">
      <div id="WEIGHT__Kg_">
        <span>WEIGHT (Kg)</span>
      </div>
      <div id="__12_00">
        <!-- <span>: <?php echo $berat;?></span> -->
        : {{ data.weight }}
      </div>
    </div>
    <div id="Group_3">
      <div id="DIMENSION_____">
        <span>DIMENSION </span>
      </div>
      <div id="__20" style="display:flex">
        <!-- <span>: <?php echo $total;?></span> -->
        <template v-if="data.long && data.wide && data.weight">
          :
          {{
            (parseInt(data.long) *
              parseInt(data.wide) *
              parseInt(data.height)) /
              5000
          }}
        </template>
        <template v-else>
          <div v-for="(item, index) in data.detail_volume" :key="index">
            <template v-if="item.panjang && item.lebar && item.tinggi">
              :
              <span>{{
                (parseInt(item.panjang) *
                  parseInt(item.lebar) *
                  parseInt(item.tinggi)) /
                  5000
              }}</span>
            </template>
            <template v-else>: 0</template>
          </div></template
        >
      </div>
    </div>
    <div id="Group_4">
      <div id="Date_____">
        <span>Date </span>
      </div>
      <div id="__20_Nov_19">
        <!-- <span>: <?php echo date('d F Y', strtotime($tgl_order));?></span> -->
        : {{ formatDate(data.createdAt) }}
      </div>
    </div>
    <div id="Group_5">
      <div id="Country_Of_Origin">
        <span>Country Of Origin</span>
      </div>
      <div id="___">
        <span> : </span>
      </div>
    </div>
    <div id="Group_6">
      <div id="INDONESIA">
        <span>INDONESIA</span>
      </div>
    </div>
    <svg class="Rectangle_19">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_19"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="1300"
        height="325"
      ></rect>
    </svg>
    <svg class="Line_43" viewBox="0 0 1299 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_43"
        d="M 0 0 L 1299 0"
      ></path>
    </svg>
    <svg class="Line_44" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_cd" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_cd"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ce" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ce"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_cf" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_cf"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_cg" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_cg"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ch" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ch"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ci" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ci"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ci2" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ci2"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ci3" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ci3"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_44_ci4" viewBox="0 0 1299.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_44_ci4"
        d="M 0 0 L 1299.5 0"
      ></path>
    </svg>
    <svg class="Line_47" viewBox="0 0 1 219">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_47"
        d="M 0 0 L 0 324"
      ></path>
    </svg>
    <svg class="Line_47_ck" viewBox="0 0 1 219">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_47_ck"
        d="M 0 0 L 0 324"
      ></path>
    </svg>
    <svg class="Line_47_cl" viewBox="0 0 1 219">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_47_cl"
        d="M 0 0 L 0 324"
      ></path>
    </svg>
    <svg class="Path_14" viewBox="0 0 1 225">
      <path
        fill="rgba(0,0,0,0)"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_14"
        d="M 0 0 L 0 35.69427490234375 L 0 297"
      ></path>
    </svg>
    <svg class="Line_48" viewBox="0 0 289 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_48"
        d="M 0 0 L 289 0"
      ></path>
    </svg>
    <div id="DESCRIPTION_OF_GOODS">
      <span>DESCRIPTION OF GOODS</span>
    </div>
    <div id="HS_CODE">
      <span>HS CODE</span>
    </div>
    <div id="QTY">
      <span>QTY</span>
    </div>
    <div id="VALUE__IDR_">
      <span>VALUE ({{ $route.query.type == "IDR" ? "IDR" : "USD" }})</span>
    </div>
    <div id="ID1__Kain_Sembagi">
      <!-- <?php
            $i=0;
            foreach ($deskripsi->result_array() as $sws):
                                $id_order=$sws['id_order'];
                                $nama_barang=$sws['nama_barang'];
                                $i++;
                        ?>
                <span><?php echo $i.". ".$nama_barang;?><br> </span>
                <?php endforeach; ?> -->
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 24px"
      >
        {{ item.item_name }}
      </h4>
    </div>
    <div id="ID1300000">
      <!-- <span><?php
                foreach ($deskripsi->result_array() as $sws):
                                    $id_order=$sws['id_order'];
                                    
                                    $hs_code=$sws['hs_code'];
                                    
                            ?>
                    <span><?php echo $hs_code;?><br> </span>
                    <?php endforeach; ?></span> -->
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 24px"
      >
        {{ item.code }}
      </h4>
    </div>
    <div id="ID7">
      <!-- <span><?php
                foreach ($deskripsi->result_array() as $sws):
                                    $id_order=$sws['id_order'];
                                    
                                    $quantity=$sws['quantity'];
                                    
                            ?>
                    <span><?php echo $quantity;?><br> </span>
                    <?php endforeach; ?></span> -->
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 24px"
      >
        {{ item.qty }}
      </h4>
    </div>
    <div id="ID10">
      <!-- <?php
            foreach ($deskripsi->result_array() as $sws):
                                $id_order=$sws['id_order'];
                                
                                $satuan=$sws['satuan'];
                                
                        ?>
                <span><?php echo $satuan;?><br> </span>
                <?php endforeach; ?> -->
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 24px"
      >
        {{ item.unit }}
      </h4>
    </div>
    <div id="ID20">
      <!-- <?php
            foreach ($deskripsi->result_array() as $sws):
                                $id_order=$sws['id_order'];
                                
                                $total_nilai=$sws['total_nilai'];
                                
                        ?>
                <span><?php echo $total_nilai;?><br> </span>
                <?php endforeach; ?> -->
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 24px"
      >
        {{ $route.query.type == "IDR" ? "IDR" : "USD" }}
        {{ formatPrice(item.total_value) }}
      </h4>
    </div>
    <div id="SUB_TOTAL_">
      <span>SUB TOTAL </span>
    </div>
    <div id="UNIT_VALUE">
      <span>UNIT VALUE</span>
    </div>
    <div id="ID140">
      <!-- <?php
        foreach ($deskripsi2->result_array() as $sws):
                            
                            
                            $total_nilai=$sws['totalsemua'];
                            
                    ?>
            <span><?php echo $total_nilai;?><br> </span>
            <?php endforeach; ?> -->
      {{ $route.query.type == "IDR" ? "IDR" : "USD"
      }}{{
        formatPrice(
          data.detail_item.reduce(
            (total, num) => total + parseInt(num.total_value),
            0
          )
        )
      }}
    </div>
    <div id="Certified_true_and_Corrected__">
      <span>Certified true and Corrected : </span>
    </div>
    <div id="ID1234">
      <!-- <span><?php echo $id_penerima;?></span> -->
      {{ data.recipient_no_id }}
    </div>
    <div id="Admin">
      <!-- <span><?php echo $admin;?></span> -->
      {{ input_by.fullname }}
    </div>
    <img id="MENTAHAN_JASKIPINcdr" src="@/assets/logo-opsional2.png" />
  </div>
</template>

<script>
import axios from "axios";
import { api_url } from "@/config/api";

export default {
  data() {
    return {
      /* Auth Token */
      headers: JSON.parse(window.localStorage.getItem("token-auth-jaskipin")),
      /* Data List */
      data: [],
      input_by: "",
      courierData: [],
    };
  },
  computed: {
    profileData() {
      let profile = JSON.parse(
        window.localStorage.getItem("data-profile-jaskipin")
      );
      if (profile) {
        return profile;
      } else {
        return null;
      }
    },
  },
  created() {
    this.getDetailOrder();
  },
  methods: {
    async getDetailOrder() {
      try {
        const response = await axios.get(
          `${api_url}/api/v1/transaction/international-order/admin/get/${this.$route.params.id}`,
          { headers: this.headers }
        );
        console.log(response);

        /* Set Data Selected */
        this.data = response.data.data[0];
        this.getInputBy(this.data.input_by);
        this.getListCourier();

        /* Print Page */
        setTimeout(function() {
          window.print();
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
    async getInputBy(id) {
      try {
        const response = await axios.get(
          `${api_url}/api/v1/users/user/get/${id}`,
          { headers: this.headers }
        );
        console.log(response);
        /* Set Data Selected */
        this.input_by = response.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async getListCourier() {
      let query_params = {
        pagination: false,
      };

      try {
        const response = await axios.get(
          `${api_url}/api/v1/shipping-rates/courier/admin`,
          { params: query_params, headers: this.headers }
        );
        console.log(response);
        response.data.map((item) => {
          if (item.courier_type == "International") {
            if (item.courier_name === this.data.courier) {
              this.courierData = item;
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let date = new Date(value).toLocaleString("en-EN", options);
      return date;
    },
    formatPrice(number) {
      var rupiah = "";
      var angkarev = number
        .toString()
        .split("")
        .reverse()
        .join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
    },
  },
};
</script>

<style scoped>
@page {
  size: landscape; /* auto is the initial value */
  margin: 0; /* this affects the margin in the printer settings */
}
.mediaViewInfo {
  --web-view-name: PROFOMA INVOICE;
  --web-view-id: PROFOMA_INVOICE;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: PROFOMA_INVOICE;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#PROFOMA_INVOICE {
  margin: 0;
  position: absolute;
  width: 1920px;
  height: 1080px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: PROFOMA INVOICE;
  --web-view-id: PROFOMA_INVOICE;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
.Rectangle_15 {
  position: absolute;
  overflow: visible;
  width: 563px;
  height: 217px;
  left: 79px;
  top: 265px;
}
.Line_19 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 290.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_25 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 216px;
  left: 200.5px;
  top: 265.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name________________y {
  position: absolute;
  left: 81px;
  top: 270px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_z {
  position: absolute;
  left: 81px;
  top: 293px;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_28 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 454.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_30 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 429.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#SITI_HAJAR_OMAR {
  position: absolute;
  left: 202px;
  top: 270px;
  overflow: visible;
  width: 157px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID36__JALAN_LANAI_KIARA__KIARA_ {
  position: absolute;
  left: 212px;
  top: 300px;
  overflow: visible;
  width: 400px;
  /* white-space: nowrap; */
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
  word-wrap: break-word;
}
#Telp_ {
  position: absolute;
  left: 81px;
  top: 432px;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID_No__ {
  position: absolute;
  left: 81px;
  top: 457px;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#_60123729577_ {
  position: absolute;
  left: 202px;
  top: 432px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#CONSIGNEE {
  position: absolute;
  left: 81px;
  top: 243px;
  overflow: visible;
  width: 107px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(203, 103, 3, 1);
  letter-spacing: -0.15px;
}
.Rectangle_16 {
  position: absolute;
  overflow: visible;
  width: 563px;
  height: 217px;
  left: 79px;
  top: 265px;
}
.Line_31 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 290.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_32 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 216px;
  left: 200.5px;
  top: 265.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name________________y {
  position: absolute;
  left: 81px;
  top: 270px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_z {
  position: absolute;
  left: 81px;
  top: 293px;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_33 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 454.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_34 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 79.5px;
  top: 429.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
/* #ID36__JALAN_LANAI_KIARA__KIARA_ {
    position: absolute;
    left: 202px;
    top: 340px;
    overflow: visible;
    width: 426px;
    white-space: nowrap;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: rgba(0,0,0,1);
    letter-spacing: -0.15px;
} */
#Telp_ {
  position: absolute;
  left: 81px;
  top: 432px;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID_No__ {
  position: absolute;
  left: 81px;
  top: 457px;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#_60123729577_ {
  position: absolute;
  left: 202px;
  top: 432px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#CONSIGNEE_ {
  position: absolute;
  left: 81px;
  top: 243px;
  overflow: visible;
  width: 107px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_35 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 290.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_36 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 187px;
  left: 939.5px;
  top: 265.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name________________bi {
  position: absolute;
  left: 820px;
  top: 270px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_bj {
  position: absolute;
  left: 820px;
  top: 293px;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_37 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 454.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_38 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 429.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Telp_bo {
  position: absolute;
  left: 820px;
  top: 432px;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#SHIPPER {
  position: absolute;
  left: 820px;
  top: 243px;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_18 {
  position: absolute;
  overflow: visible;
  width: 563px;
  height: 190px;
  left: 818px;
  top: 265px;
}
.Line_39 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 290.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_40 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 189px;
  left: 939.5px;
  top: 265.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name________________bi {
  position: absolute;
  left: 820px;
  top: 270px;
  overflow: visible;
  width: 119px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_bj {
  position: absolute;
  left: 820px;
  top: 293px;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_41 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 454.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_42 {
  overflow: visible;
  position: absolute;
  width: 562.5px;
  height: 1px;
  left: 818.5px;
  top: 429.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#AIRA_B___MAMA_AZMI {
  position: absolute;
  left: 941px;
  top: 269px;
  overflow: visible;
  width: 176px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Cirebon___West_Java___Indonesi {
  position: absolute;
  left: 948px;
  top: 300px;
  overflow: visible;
  width: 300px;
  /* white-space: nowrap; */
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
  word-wrap: break-word;
}
#Telp_bo {
  position: absolute;
  left: 820px;
  top: 432px;
  overflow: visible;
  width: 33px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID081222111346 {
  position: absolute;
  left: 941px;
  top: 432px;
  overflow: visible;
  width: 115px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#COMMERCIAL_INVOICE {
  position: absolute;
  left: 543.5px;
  top: 158.5px;
  overflow: visible;
  width: 435px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 39px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.1px;
}
#Repeat_Grid_3 {
  position: absolute;
  width: 92px;
  height: 20px;
  left: 80px;
  top: 510px;
  overflow: hidden;
}
#Repeat_Grid_3_0 {
  position: absolute;
  width: 91px;
  height: 20px;
  left: 1px;
  top: 0px;
  overflow: visible;
}
#AWB_No_____ {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__31532637802_bv {
  position: absolute;
  left: 215px;
  top: 510px;
  overflow: visible;
  width: 118px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__31532637802_bv {
  position: absolute;
  left: 215px;
  top: 510px;
  overflow: visible;
  width: 118px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Group_2 {
  position: absolute;
  width: 187px;
  height: 20px;
  left: 81px;
  top: 536px;
  overflow: visible;
}
#WEIGHT__Kg_ {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 109px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__12_00 {
  position: absolute;
  left: 134px;
  top: 0px;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Group_3 {
  position: absolute;
  width: 163px;
  height: 20px;
  left: 81px;
  top: 562px;
  overflow: visible;
}
#DIMENSION_____ {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 125px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__20 {
  position: absolute;
  left: 134px;
  top: 0px;
  overflow: visible;
  width: 30px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Group_4 {
  position: absolute;
  width: 225px;
  height: 20px;
  left: 81px;
  top: 588px;
  overflow: visible;
}
#Date_____ {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 62px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__20_Nov_19 {
  position: absolute;
  left: 134px;
  top: 0px;
  overflow: visible;
  width: 92px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Group_5 {
  position: absolute;
  width: 169px;
  height: 20px;
  left: 820px;
  top: 536px;
  overflow: visible;
}
#Country_Of_Origin {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 137px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#___ {
  position: absolute;
  left: 155px;
  top: 0px;
  overflow: visible;
  width: 15px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Group_6 {
  position: absolute;
  width: 97px;
  height: 20px;
  left: 1000px;
  top: 536px;
  overflow: visible;
}
#INDONESIA {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
  width: 98px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_19 {
  position: absolute;
  overflow: visible;
  width: 1300px;
  height: 255px;
  left: 81px;
  top: 614px;
}
.Line_43 {
  overflow: visible;
  position: absolute;
  width: 1299px;
  height: 1px;
  left: 81.5px;
  top: 664.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44 {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 689.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_cd {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 713.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ce {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 737.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_cf {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 761.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_cg {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 785.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ch {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 809.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ci {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 833.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ci2 {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 856.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ci3 {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 880.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_44_ci4 {
  overflow: visible;
  position: absolute;
  width: 1299.5px;
  height: 1px;
  left: 81.5px;
  top: 905.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_47 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 219px;
  left: 911.5px;
  top: 614.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_47_ck {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 219px;
  left: 1001.65px;
  top: 614.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_47_cl {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 219px;
  left: 1091.8px;
  top: 614.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Path_14 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 225px;
  left: 1212.95px;
  top: 642.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_48 {
  overflow: visible;
  position: absolute;
  width: 289px;
  height: 1px;
  left: 1091.5px;
  top: 642.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#DESCRIPTION_OF_GOODS {
  position: absolute;
  left: 395px;
  top: 632px;
  overflow: visible;
  width: 209px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#HS_CODE {
  position: absolute;
  left: 919px;
  top: 632px;
  overflow: visible;
  width: 77px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#QTY {
  position: absolute;
  left: 1030px;
  top: 633px;
  overflow: visible;
  width: 35px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#VALUE__IDR_ {
  position: absolute;
  left: 1188px;
  top: 621px;
  overflow: visible;
  width: 98px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID1__Kain_Sembagi {
  position: absolute;
  left: 86px;
  top: 666px;
  overflow: visible;
  line-height: 25px;
  width: 129px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID1300000 {
  position: absolute;
  left: 929px;
  top: 666px;
  overflow: visible;
  line-height: 25px;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID7 {
  position: absolute;
  left: 1040px;
  top: 666px;
  overflow: visible;
  width: 11px;
  line-height: 25px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID10 {
  position: absolute;
  left: 1130px;
  line-height: 25px;
  top: 666px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID20 {
  position: absolute;
  left: 1250px;
  top: 666px;
  line-height: 25px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#SUB_TOTAL_ {
  position: absolute;
  left: 1259px;
  top: 647px;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#UNIT_VALUE {
  position: absolute;
  left: 1116px;
  top: 647px;
  overflow: visible;
  width: 75px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID140 {
  position: absolute;
  left: 1250px;
  top: 914px;
  overflow: visible;
  width: 30px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Certified_true_and_Corrected__ {
  position: absolute;
  left: 81px;
  top: 976px;
  overflow: visible;
  width: 230px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID1234 {
  position: absolute;
  left: 202px;
  top: 458px;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Admin {
  position: absolute;
  left: 310px;
  top: 976px;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#MENTAHAN_JASKIPINcdr {
  position: absolute;
  width: 120px;
  height: auto;
  left: 75px;
  top: 104px;
  overflow: visible;
}
</style>
