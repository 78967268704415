import { render, staticRenderFns } from "./piutang-agen.vue?vue&type=template&id=53f6c571&scoped=true&"
import script from "./piutang-agen.vue?vue&type=script&lang=js&"
export * from "./piutang-agen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f6c571",
  null
  
)

export default component.exports