import { render, staticRenderFns } from "./piutang-pelanggan.vue?vue&type=template&id=4f928c46&scoped=true&"
import script from "./piutang-pelanggan.vue?vue&type=script&lang=js&"
export * from "./piutang-pelanggan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f928c46",
  null
  
)

export default component.exports