import axios from "axios";

const ApiService = () => {
  const token = JSON.parse(localStorage.getItem("token-auth-jaskipin"));
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      token: token
        ? JSON.parse(localStorage.getItem("token-auth-jaskipin")).token
        : "",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  // // // Create instance
  let instance = axios.create(defaultOptions);

  return instance;
};

export default ApiService();
