<template>
  <div id="resi">
    <svg class="Rectangle_1">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_1"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="119"
      ></rect>
    </svg>
    <div id="JASKIPIN_COURIER_EXPRESS">
      <span>JASKIPIN EXPRESS INDONESIA</span>
    </div>
    <div id="www_Jaskipin_co_id">
      <span>www.jaskipin.co.id</span>
    </div>
    <svg class="Rectangle_2">
      <rect
        fill="rgba(13,49,117,1)"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_2"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="24"
      ></rect>
    </svg>
    <div id="Shipment_Number">
      <span>Shipment Number</span>
    </div>
    <div id="JCRX0000000">
      <span>{{ data.shipment_number }}</span>
    </div>
    <svg class="Path_2" viewBox="129 182 1 119.062">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_2"
        d="M 129 181.9999847412109 C 129 181.9999847412109 129 271.2966003417969 129 301.0621337890625"
      ></path>
    </svg>
    <svg class="Line_1" viewBox="0 0 1 21">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_1"
        d="M 0 0 L 0 21"
      ></path>
    </svg>
    <div id="ID1">
      <span>1</span>
    </div>
    <svg class="Line_2" viewBox="0 0 1 21">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_2"
        d="M 0 0 L 0 21"
      ></path>
    </svg>
    <div id="ID3">
      <span>3</span>
    </div>
    <svg class="Line_3" viewBox="0 0 1 21">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_3"
        d="M 0 0 L 0 21"
      ></path>
    </svg>
    <div id="From__Sender_">
      <span>From (Sender)</span>
    </div>
    <div id="CONSIGNMENT_NOTE">
      <span>CONSIGNMENT NOTE</span>
    </div>
    <div id="AIRWAYBILL">
      <span>AIRWAYBILL</span>
    </div>
    <div id="__31532637802">
      <span>: </span
      ><span style="font-style:normal;font-weight:bold;">
        {{ data.awb_no }}
      </span>
    </div>
    <svg class="Rectangle_11">
      <rect
        fill="rgba(13,49,117,1)"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_11"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="24"
      ></rect>
    </svg>
    <svg class="Line_4" viewBox="0 0 1 21.17">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_4"
        d="M 0 0 L 0 21.16986083984375"
      ></path>
    </svg>
    <div id="ID2">
      <span>2</span>
    </div>
    <svg class="Line_5" viewBox="0 0 1 21.17">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_5"
        d="M 0 0 L 0 21.16986083984375"
      ></path>
    </svg>
    <div id="ID4">
      <span>4</span>
    </div>
    <svg class="Line_6" viewBox="0 0 1 21.17">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_6"
        d="M 0 0 L 0 21.16986083984375"
      ></path>
    </svg>
    <div id="AUTHORIZATION">
      <span>AUTHORIZATION</span>
    </div>
    <svg class="Rectangle_12">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_12"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="172"
      ></rect>
    </svg>
    <svg class="Path_4" viewBox="129 182 1 172">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_4"
        d="M 129 182 C 129 182 129 311 129 354"
      ></path>
    </svg>
    <svg class="Path_8" viewBox="129 318 1 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_8"
        d="M 129 318"
      ></path>
    </svg>
    <svg class="Rectangle_16">
      <rect
        fill="rgba(13,49,117,1)"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_16"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="24"
      ></rect>
    </svg>
    <svg class="Line_7" viewBox="0 0 1 22">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_7"
        d="M 0 0 L 0 22"
      ></path>
    </svg>
    <div id="ID5">
      <span>5</span>
    </div>
    <svg class="Line_8" viewBox="0 0 1 23">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_8"
        d="M 0 0 L 0 23"
      ></path>
    </svg>
    <div id="ID7">
      <span>7</span>
    </div>
    <svg class="Line_9" viewBox="0 0 1 22">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_9"
        d="M 0 0 L 0 22"
      ></path>
    </svg>
    <div id="Received_By_Courier">
      <span>Received By Courier</span>
    </div>
    <div id="SHIPMENT_INFORMATION">
      <span>SHIPMENT INFORMATION</span>
    </div>
    <svg class="Rectangle_17">
      <rect
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Rectangle_17"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="869"
        height="244"
      ></rect>
    </svg>
    <svg class="Path_11" viewBox="129 182 1 155.054">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_11"
        d="M 129 182 C 129 182 129 298.290283203125 129 426"
      ></path>
    </svg>
    <svg class="Path_12" viewBox="129 182 1 155.054">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_12"
        d="M 129 182 C 129 182 129 298.290283203125 129 426"
      ></path>
    </svg>
    <svg class="Path_13" viewBox="129 182 1 155.054">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_13"
        d="M 129 182 C 129 182 129 298.290283203125 129 426"
      ></path>
    </svg>
    <svg class="Line_13" viewBox="0 0 217 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_13"
        d="M 0 0 L 217 0"
      ></path>
    </svg>
    <svg class="Line_14" viewBox="0 0 371.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_14"
        d="M 0 0 L 371.5 0"
      ></path>
    </svg>
    <svg class="Path_14" viewBox="129 182 1 23.5">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Path_14"
        d="M 129 182 C 129 182 129 199.6249694824219 129 205.4999694824219"
      ></path>
    </svg>
    <svg class="Line_15" viewBox="0 0 1 22">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_15"
        d="M 0 0 L 0 22"
      ></path>
    </svg>
    <div id="ID6">
      <span>6</span>
    </div>
    <svg class="Line_16" viewBox="0 0 1 22">
      <path
        fill="transparent"
        stroke="rgba(255,255,255,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_16"
        d="M 0 0 L 0 22"
      ></path>
    </svg>
    <div id="Name_______________">
      <span>Name </span>
    </div>
    <div id="Date">
      <span>Date</span>
    </div>
    <div id="Signature">
      <span>Signature</span>
    </div>
    <div id="ID20_11_2019">
      <span>{{ formatDate(data.createdAt) }}</span>
    </div>
    <div id="Name________________bu">
      <span>Name </span>
    </div>
    <div id="AIRA_B___MAMA_AZMI____________">
      <span>{{ data.sender_name }}</span>
    </div>
    <div id="signature__name">
      <!-- <span>{{ data && data.agen_general ? profileData.phone : data.agen.phone }}</span> -->
      <span v-if="data.agen_general">{{ profileData.phone }}</span>
      <span v-else-if="data.agen">{{ data.agen.phone }}</span>
    </div>
    <div id="Signature__Sender_">
      <span>Signature (Sender)</span>
    </div>
    <div id="Repeat_Grid_3">
      <div id="Group_1">
        <svg class="Line_18" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_b" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_b"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ca" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ca"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ce" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ce"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cf" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cf"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ch" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ch"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ci" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ci"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ck" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ck"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_co" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_co"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cs" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cs"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ct" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ct"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_cz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_cz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_c" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_c"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_da" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_da"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_db" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_db"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_de" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_de"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_df" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_df"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_di" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_di"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dk" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dk"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_do" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_do"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ds" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ds"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dt" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dt"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_du" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_du"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_dz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_dz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_d" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_d"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ea" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ea"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ec" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ec"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ed" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ed"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ee" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ee"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ef" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ef"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ei" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ei"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ej" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ej"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ek" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ek"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_el" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_el"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_em" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_em"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_en" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_en"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eo" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eo"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ep" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ep"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_er" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_er"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_es" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_es"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_et" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_et"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_eu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_eu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ev" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ev"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ew" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ew"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ex" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ex"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ey" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ey"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ez" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ez"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_e" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_e"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fa" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fa"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fe" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fe"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ff" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ff"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fi" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fi"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fk" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fk"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fo" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fo"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fs" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fs"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ft" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ft"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_fz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_fz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_f" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_f"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ga" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ga"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ge" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ge"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gf" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gf"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gi" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gi"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gk" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gk"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_go" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_go"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gs" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gs"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gt" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gt"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_gz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_gz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_g" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_g"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ha" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ha"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_he" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_he"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hf" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hf"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hi" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hi"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hk" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hk"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ho" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ho"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hs" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hs"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ht" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ht"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_hz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_hz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_h" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_h"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ia" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ia"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ib" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ib"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ic" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ic"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_id" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_id"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ie" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ie"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_if" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_if"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ig" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ig"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ih" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ih"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ii" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ii"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ij" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ij"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ik" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ik"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_il" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_il"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_im" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_im"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_in" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_in"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_io" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_io"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ip" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ip"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ir" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ir"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_is" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_is"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_it" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_it"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iu" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iu"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ix" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ix"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_iz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_iz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_i" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_i"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ja" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ja"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jb" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jb"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jc" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jc"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jd" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jd"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_je" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_je"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jf" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jf"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jg" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jg"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jh" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jh"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ji" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ji"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jj" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jj"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jk" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jk"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jl" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jl"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jm" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jm"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jn" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jn"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jo" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jo"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jp" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jp"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jq" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jq"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jr" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jr"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_js" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_js"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jt" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jt"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_ju" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_ju"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jv" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jv"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jw" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jw"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jx" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jx"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jy" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jy"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_jz" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_jz"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
        <svg class="Line_18_j" viewBox="0 0 1 1">
          <path
            fill="transparent"
            stroke="rgba(0,0,0,1)"
            stroke-width="1px"
            stroke-linejoin="miter"
            stroke-linecap="round"
            stroke-miterlimit="4"
            shape-rendering="auto"
            id="Line_18_j"
            d="M 0 0 L 1 0"
          ></path>
        </svg>
      </div>
    </div>
    <div id="Windu">
      <span v-if="data.agen_general">{{ profileData.fullname }}</span>
      <span v-else-if="data.agen">{{ data.agen.fullname }}</span>
    </div>
    <div id="Jasa_Kirim_Paket_Internasional">
      <span>Jasa Kirim Paket Internasional</span>
    </div>
    <!-- <div id="CARRIER">
            <span>CARRIER</span>
        </div>
        <div id="__Aramex">
            <span>: {{ courierData.code }}</span>
        </div> -->
    <svg class="Rectangle_21">
      <rect
        fill="rgba(255,255,255,1)"
        id="Rectangle_21"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="206"
        height="73"
      ></rect>
    </svg>
    <div id="A">
      <span>
        <svg id="barcode"></svg>
        {{ generateBarcode(data.shipment_number) }}
        <!-- <div v-html="generateBarcode(data.shipment_number)"></div> -->
        <!-- <script>
            JsBarcode("#barcode", "<?=$id_order ?>");
            </script> -->
      </span>
    </div>
    <div id="Name_ij">
      <span>Name</span>
    </div>
    <div id="__AIRA_B___MAMA_AZMI">
      <span>:</span
      ><span style="font-style:normal;font-weight:bold;">
        {{ data.sender_name }}
      </span>
    </div>
    <div id="Phone_ik">
      <span>Phone</span>
    </div>
    <div id="__081222111346_il">
      <span>: {{ data.sender_phone }}</span>
    </div>
    <div id="Address_im">
      <span>Address</span>
    </div>
    <!-- <div id="__Cirebon___west_Java___Indone_in">
            <span>: Alamat Pengirim >>>>></span>
        </div> -->
    <div id="To__Receiver_">
      <span>To (Receiver)</span>
    </div>
    <svg class="Line_19" viewBox="0 0 497.238 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_19"
        d="M 0 0 L 497.238037109375 0"
      ></path>
    </svg>
    <svg class="Line_20" viewBox="0 0 497.238 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_20"
        d="M 0 0 L 497.238037109375 0"
      ></path>
    </svg>
    <svg class="Line_21" viewBox="0 0 1 97">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_21"
        d="M 0 0 L 0 188"
      ></path>
    </svg>
    <svg class="Line_22" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_22"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_23" viewBox="0 0 1 97">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_23"
        d="M 0 0 L 0 188"
      ></path>
    </svg>
    <div id="Name_ij">
      <span>Name</span>
    </div>
    <div id="Phone_ik">
      <span>Phone</span>
    </div>
    <div id="__081222111346_il">
      <span>: </span>
    </div>
    <div id="Address_im">
      <span>Address</span>
    </div>
    <div id="__Cirebon___west_Java___Indone_in">
      <span>: {{ data.sender_address }}</span>
    </div>
    <div id="Name_io">
      <span>Name</span>
    </div>
    <div id="__SITI_HAJAR_OMAR">
      <span>: {{ data.recipient_name }}</span>
    </div>
    <div id="Phone_iq">
      <span>Phone</span>
    </div>
    <div id="__">
      <span>: </span>
    </div>
    <div id="Delivery_Address">
      <span>Delivery Address</span>
    </div>
    <div id="___it">
      <span>: </span>
    </div>
    <div id="Post_code">
      <span>Post code</span>
    </div>
    <div id="__51000">
      <span>: {{ data.recipient_postal_code }}</span>
    </div>
    <div id="Country">
      <span>Country</span>
    </div>
    <div id="__Malaysia">
      <span>: {{ data.recipient_destination }}</span>
    </div>
    <div id="_60123729577">
      <span>{{ data.recipient_phone }}</span>
    </div>
    <div id="ID36__JALAN_LANAI_KIARA__KIARA">
      <span>{{ data.recipient_address }}</span>
    </div>
    <div id="ID1__Jika_barang_di_tolak_oleh">
      <span
        >1. Jika barang di tolak oleh Custom Negara setempat maka bukan
        tanggungjawab JASKIPIN EXPRESS INDONESIA
      </span>
    </div>
    <div id="ID2__Ongkos_Kirim_tidak_termas">
      <span
        >2. Ongkos Kirim tidak termasuk biaya import/Duty Tax jika muncul di
        negara tujuan
      </span>
    </div>
    <div id="ID3__Customer_Wajib_Memberikan">
      <span
        >3. Customer Wajib Memberikan informasi detail barang yang di kirim
        (harga & jumlah barang), Apabila customer tidak<br />
        memberikan informasi maka JASKIPIN EXPRESS INDONESIA akan menentukan
        detail barang tersebut(harga & jumlah)
      </span>
    </div>
    <div id="ID4__Jika_Detail_barang_yang_d">
      <span
        >4. Jika Detail barang yang di berikan tidak sesuai dengan Commercial
        Invoice dan di tahan oleh Custom Negara <br />
        setempat maka biaya di tanggung Customer
      </span>
    </div>
    <div id="ID5__JASKIPIN_EXPRESS_tidak_be">
      <span
        >5. JASKIPIN EXPRESS INDONESIA tidak bertanggung jawab atas segala
        resiko yang di sebabkan oleh keterangan yang tidak <br />
        benar dari pihak pengirim terkair isi paket kirimannya</span
      >
    </div>
    <div id="ID6__Jika_barang_di_cek_kembal">
      <span
        >6. Jika barang di cek kembali oleh Custom Negara setempat sehingga
        terjadi perubahan untuk jumlah barang <br />
        ataupun kondisi packing maka bukan tanggungjawab dari JASKIPIN EXPRESS
        INDONESIA</span
      >
    </div>
    <div id="ID7__JASKIPIN_EXPRESS_akan_ber">
      <span
        >7. JASKIPIN EXPRESS INDONESIA akan bertanggung jawab dengan melakukan
        penggantian senilai harga barang atau <br />
        maksimum sebesar Rp. 1.000.000 (Satu Juta Rupiah)bilamana terjadi
        kehilangan atas barang yang di kirim</span
      >
    </div>
    <div id="ID8__Dilarang_mengirim_uang_tu">
      <span
        >8. Dilarang mengirim uang tunai, senjata api/tajam, surat berharga,
        perhiasan dan barang berharga, obat terlarang, <br />
        dan barang yang termasuk dalam kategori dilarang atau melanggar hukum
        baik di negara asal maupun di negara</span
      >
    </div>
    <div id="ID9__Dilarang_mengirim_uang_tu">
      <span
        >9. JASKIPIN EXPRESS INDONESIA tidak bertanggung jawab atas segala
        kerusakan paket yang disebabkan oleh packing <br />
        yang tidak proper (pengemasan yang tidak aman) dari pihak pengirim.
      </span>
    </div>

    <svg class="Line_24" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_24"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_25" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_25"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_26" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_26"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_27" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_27"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_28" viewBox="0 0 1 49">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_28"
        d="M 0 0 L 0 49"
      ></path>
    </svg>
    <svg class="Line_29" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_je" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_je"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jf" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jf"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg2" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg2"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg3" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg3"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg4" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg4"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg5" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg5"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <svg class="Line_29_jg6" viewBox="0 0 497.5 1">
      <path
        fill="transparent"
        stroke="rgba(0,0,0,1)"
        stroke-width="1px"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        shape-rendering="auto"
        id="Line_29_jg6"
        d="M 0 0 L 497.5 0"
      ></path>
    </svg>
    <div id="No__Of_Pieces________________">
      <span>No. Of Pieces<br /><br /> </span>
    </div>
    <div id="ID1_ji">
      <span>{{ data.bag_amount }}</span>
    </div>
    <div id="Actual_weight__Kg_____________">
      <span>Actual weight (Kg)<br /><br /> </span>
    </div>
    <div id="ID12_0" style="margin-top:-12px;">
      <span>{{ data.weight }}</span>
      <!-- <div v-for="(item, index) in data.detail_volume" :key="index">
        <span>{{ item.berat ? item.berat : 0 }}</span>
      </div> -->
    </div>
    <!-- <div id="ID1_jl">
            <span>1</span>
        </div>
        <div id="ID2_jm">
            <span>2</span>
        </div>
        <div id="ID3_jn">
            <span>3</span>
        </div> -->
    <div id="Kain_Sembagi__20_Set_">
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 18px"
      >
        {{ item.item_name }}
      </h4>
      <!-- <?php
        foreach ($deskripsi->result_array() as $sws):
                            $id_order=$sws['id_order'];
                            $nama_barang=$sws['nama_barang'];
                            
                    ?>
            <span><?php echo $nama_barang;?><br> </span>
            <?php endforeach; ?> -->
    </div>
    <div id="Kain_Sembagi__20_Set_2">
      <h4
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 18px"
      >
        {{ index + 1 }}
      </h4>
    </div>
    <div id="USD">
      <span></span>
    </div>
    <div id="ID120">
      <!-- <?php
        foreach ($deskripsi->result_array() as $sws):
                            $id_order=$sws['id_order'];
                            
                            $total_nilai=$sws['total_nilai'];
                            
                    ?>
            <span><?php echo "US$  ".$total_nilai;?><br> </span>
            <?php endforeach; ?> -->
      <span
        v-for="(item, index) in data.detail_item"
        :key="index"
        style="line-height: 18px"
      >
        {{ $route.query.type == "IDR" ? "IDR" : "USD"
        }}{{ item.total_value ? formatPrice(item.total_value) : "Rp0" }}<br />
      </span>
    </div>
    <div id="Chargeable_weight__Kg_________">
      <span>Chargeable weight (Kg)<br /><br /> </span>
    </div>
    <div id="ID12_0_js" style="margin-top: -12px;">
      <!-- <template>
        <span>{{ data.charge_weight ? data.charge_weight : 0 }}</span>
      </template> -->
      <template v-if="data.long && data.wide && data.height">
        {{
          parseInt(data.weight) >
          (parseInt(data.long) * parseInt(data.wide) * parseInt(data.height)) /
            5000
            ? data.weight
            : (parseInt(data.long) *
                parseInt(data.wide) *
                parseInt(data.height)) /
              5000
        }}
      </template>
      <template v-else>
        {{ data.charge_weight ? data.charge_weight : data.weight }}
      </template>
    </div>
    <div id="Length___Kg_________________">
      <span>Length <br />(Kg)<br /><br /> </span>
    </div>
    <div id="ID20_0" style="margin-top:-10px">
      <div v-for="(item, index) in data.detail_volume" :key="index">
        <span>{{ item.panjang ? item.panjang : 0 }}</span>
      </div>

      <!-- {{ data.long }} -->
      <!-- <span><?php echo $panjang;?></span> -->
    </div>
    <div id="width__Kg_________________">
      <span>width<br />(Kg)<br /><br /> </span>
    </div>
    <div id="ID41_0" style="margin-top:-10px">
      <div v-for="(item, index) in data.detail_volume" :key="index">
        <span>{{ item.lebar ? item.lebar : 0 }}</span>
      </div>
      <!-- {{ data.wide }} -->
      <!-- <span><?php echo $lebar;?></span> -->
    </div>
    <div id="Height__Kg_________________">
      <span>Height<br />(Kg)<br /><br /> </span>
    </div>
    <div id="ID30_0" style="margin-top:-10px">
      <div v-for="(item, index) in data.detail_volume" :key="index">
        <span>{{ item.tinggi ? item.tinggi : 0 }}</span>
      </div>
      <!-- {{ data.height }} -->
      <!-- <span><?php echo $tinggi;?></span> -->
    </div>
    <div id="Volumetric__Kg________________">
      <span>Volumetric<br />(Kg)<br /><br /> </span>
    </div>
    <div id="ID30_0_j" style="margin-top: -10px;">
      <div v-for="(item, index) in data.detail_volume" :key="index">
        <span v-if="item.panjang && item.lebar && item.tinggi">
          {{
            (parseInt(item.panjang) *
              parseInt(item.lebar) *
              parseInt(item.tinggi)) /
              5000
          }}
        </span>
        <span v-else>0</span>
      </div>
      <!-- <span><?php echo $total;?></span> -->
    </div>
    <svg class="Rectangle_22">
      <rect
        fill="rgba(143,171,225,1)"
        id="Rectangle_22"
        rx="0"
        ry="0"
        x="0"
        y="0"
        width="496"
        height="12"
      ></rect>
    </svg>
    <div id="No">
      <span>No</span>
    </div>
    <div id="Description_of_goods">
      <span>Description of goods</span>
    </div>
    <div id="Custom_Value">
      <span>Custom Value</span>
    </div>
    <!-- <div id="ID4_j">
            <span>4</span>
        </div>
        <div id="ID5_j">
            <span>5</span>
        </div> -->
    <img id="MENTAHAN_JASKIPINcdr" src="@/assets/logo-opsional2.png" />
  </div>
</template>

<script>
import axios from "axios";
import { api_url } from "@/config/api";
import Printd from "printd";

export default {
  data() {
    return {
      /* Auth Token */
      headers: JSON.parse(window.localStorage.getItem("token-auth-jaskipin")),
      /* Data List */
      data: [],
      input_by: "",
      courierData: [],
      cssText: `svg#barcode {
                margin: 0px;
                width: 200px;
                margin-top: -50px;
            }
            .mediaViewInfo {
                --web-view-name: resi;
                --web-view-id: resi;
                --web-scale-on-resize: true;
                --web-enable-deep-linking: true;
            }
            :root {
                --web-view-ids: resi;
            }
            * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                border: none;
            }
            #resi {
                position: absolute;
                width: 923px;
                height: 900px;
                background-color: rgba(255,255,255,1);
                overflow: hidden;
                --web-view-name: resi;
                --web-view-id: resi;
                --web-scale-on-resize: true;
                --web-enable-deep-linking: true;
            }
            .Rectangle_1 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 119px;
                left: 19px;
                top: 162px;
            }
            #signature__name {
                position: absolute;
                left: 93px;
                top: 548px;
                overflow: visible;
                width: 54px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #JASKIPIN_COURIER_EXPRESS {
                position: absolute;
                left: 105px;
                top: 71px;
                overflow: visible;
                width: 301px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial Rounded MT;
                font-style: normal;
                font-weight: bold;
                font-size: 21px;
                line-height: 22px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #www_Jaskipin_co_id {
                position: absolute;
                left: 105px;
                top: 110px;
                overflow: visible;
                width: 122px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial Rounded MT;
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 14px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_2 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 24px;
                left: 19px;
                top: 138px;
            }
            #Shipment_Number {
                position: absolute;
                left: 752px;
                top: 83px;
                overflow: visible;
                width: 136px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 17px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #JCRX0000000 {
                position: absolute;
                left: 714px;
                top: 98px;
                overflow: visible;
                width: 155px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 25px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Path_2 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 119.062px;
                left: 390.581px;
                top: 162px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_1 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21px;
                left: 44.5px;
                top: 139.198px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID1 {
                position: absolute;
                left: 28px;
                top: 144px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_2 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21px;
                left: 413.671px;
                top: 139.198px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID3 {
                position: absolute;
                left: 399px;
                top: 144px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_3 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21px;
                left: 390.044px;
                top: 139.198px;
                transform: matrix(1,0,0,1,0,0);
            }
            #From__Sender_ {
                position: absolute;
                left: 48px;
                top: 144px;
                overflow: visible;
                width: 71px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            #CONSIGNMENT_NOTE {
                position: absolute;
                left: 417px;
                top: 144px;
                overflow: visible;
                width: 114px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            #AIRWAYBILL {
                position: absolute;
                left: 393px;
                top: 167px;
                overflow: visible;
                width: 64px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__31532637802 {
                position: absolute;
                left: 481px;
                top: 167px;
                overflow: visible;
                width: 72px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_11 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 24px;
                left: 19px;
                top: 281px;
            }
            .Line_4 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21.17px;
                left: 44.5px;
                top: 282.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID2 {
                position: absolute;
                left: 28px;
                top: 287px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_5 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21.17px;
                left: 413.671px;
                top: 282.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID4 {
                position: absolute;
                left: 399px;
                top: 287px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_6 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 21.17px;
                left: 390.044px;
                top: 282.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            #AUTHORIZATION {
                position: absolute;
                left: 420px;
                top: 287px;
                overflow: visible;
                width: 88px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_12 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 172px;
                left: 19px;
                top: 308px;
            }
            .Path_4 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 172px;
                left: 390.581px;
                top: 308px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Path_8 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 1px;
                left: 685.913px;
                top: 441px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Rectangle_16 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 24px;
                left: 19px;
                top: 480px;
            }
            .Line_7 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 22px;
                left: 44.5px;
                top: 480.968px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID5 {
                position: absolute;
                left: 28px;
                top: 485px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_8 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 23px;
                left: 413.671px;
                top: 480.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID7 {
                position: absolute;
                left: 399px;
                top: 485px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_9 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 22px;
                left: 390.044px;
                top: 480.968px;
                transform: matrix(1,0,0,1,0,0);
            }
            #Received_By_Courier {
                position: absolute;
                left: 48px;
                top: 485px;
                overflow: visible;
                width: 99px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            #SHIPMENT_INFORMATION {
                position: absolute;
                left: 418px;
                top: 485px;
                overflow: visible;
                width: 134px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_17 {
                position: absolute;
                overflow: visible;
                width: 869px;
                height: 156px;
                left: 19px;
                top: 503px;
            }
            .Path_11 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 155.054px;
                left: 390.581px;
                top: 503.946px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Path_12 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 155.054px;
                left: 90px;
                top: 503.946px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Path_13 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 155.054px;
                left: 237.009px;
                top: 503.946px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_13 {
                overflow: visible;
                position: absolute;
                width: 217px;
                height: 1px;
                left: 19.5px;
                top: 544.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_14 {
                overflow: visible;
                position: absolute;
                width: 371.5px;
                height: 1px;
                left: 19px;
                top: 527.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Path_14 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 23.5px;
                left: 281.41px;
                top: 503.946px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_15 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 22px;
                left: 260.098px;
                top: 480.968px;
                transform: matrix(1,0,0,1,0,0);
            }
            #ID6 {
                position: absolute;
                left: 246px;
                top: 485px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_16 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 22px;
                left: 236.472px;
                top: 480.968px;
                transform: matrix(1,0,0,1,0,0);
            }
            #Name_______________ {
                position: absolute;
                left: 23px;
                top: 513px;
                overflow: visible;
                width: 73px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Date {
                position: absolute;
                left: 23px;
                top: 529px;
                overflow: visible;
                width: 24px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Signature {
                position: absolute;
                left: 23px;
                top: 546px;
                overflow: visible;
                width: 47px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID20_11_2019 {
                position: absolute;
                left: 93px;
                top: 529px;
                overflow: visible;
                width: 54px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Name________________bu {
                position: absolute;
                left: 238px;
                top: 513px;
                overflow: visible;
                width: 73px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #AIRA_B___MAMA_AZMI____________ {
                position: absolute;
                left: 284px;
                top: 513px;
                overflow: visible;
                width: 147px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Signature__Sender_ {
                position: absolute;
                left: 266px;
                top: 485px;
                overflow: visible;
                width: 91px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            #Repeat_Grid_3 {
                position: absolute;
                width: 870px;
                height: 1px;
                left: 19px;
                top: 823px;
                overflow: hidden;
            }
            #Group_1 {
                position: absolute;
                width: 869px;
                height: 1px;
                left: 0.5px;
                top: 0.5px;
                overflow: visible;
            }
            .Line_18 {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 0px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_b {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 4px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ca {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 8px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 12px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 16px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 20px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ce {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 24px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cf {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 28px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 32px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ch {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 36px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ci {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 40px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 44px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ck {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 48px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 52px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 56px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 60px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_co {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 64px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 68px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 72px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 76px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cs {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 80px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ct {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 84px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 88px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 92px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 96px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 100px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 104px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_cz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 108px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_c {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 112px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_da {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 116px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_db {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 120px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 124px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 128px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_de {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 132px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_df {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 136px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 140px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 144px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_di {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 148px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 152px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dk {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 156px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 160px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 164px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 168px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_do {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 172px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 176px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 180px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 184px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ds {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 188px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dt {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 192px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_du {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 196px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 200px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 204px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 208px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 212px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_dz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 216px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_d {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 220px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ea {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 224px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 228px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ec {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 232px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ed {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 236px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ee {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 240px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ef {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 244px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 248px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 252px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ei {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 256px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ej {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 260px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ek {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 264px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_el {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 268px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_em {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 272px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_en {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 276px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eo {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 280px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ep {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 284px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 288px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_er {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 292px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_es {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 296px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_et {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 300px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_eu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 304px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ev {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 308px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ew {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 312px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ex {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 316px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ey {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 320px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ez {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 324px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_e {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 328px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fa {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 332px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 336px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 340px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 344px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fe {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 348px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ff {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 352px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 356px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 360px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fi {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 364px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 368px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fk {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 372px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 376px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 380px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 384px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fo {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 388px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 392px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 396px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 400px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fs {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 404px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ft {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 408px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 412px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 416px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 420px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 424px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 428px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_fz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 432px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_f {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 436px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ga {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 440px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 444px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 448px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 452px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ge {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 456px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gf {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 460px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 464px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 468px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gi {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 472px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 476px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gk {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 480px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 484px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 488px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 492px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_go {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 496px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 500px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 504px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 508px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gs {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 512px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gt {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 516px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 520px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 524px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 528px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 532px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 536px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_gz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 540px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_g {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 544px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ha {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 548px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 552px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 556px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 560px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_he {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 564px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hf {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 568px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 572px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 576px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hi {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 580px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 584px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hk {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 588px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 592px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 596px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 600px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ho {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 604px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 608px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 612px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 616px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hs {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 620px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ht {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 624px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 628px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 632px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 636px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 640px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 644px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_hz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 648px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_h {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 652px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ia {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 656px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ib {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 660px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ic {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 664px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_id {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 668px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ie {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 672px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_if {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 676px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ig {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 680px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ih {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 684px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ii {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 688px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ij {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 692px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ik {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 696px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_il {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 700px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_im {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 704px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_in {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 708px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_io {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 712px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ip {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 716px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 720px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ir {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 724px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_is {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 728px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_it {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 732px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iu {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 736px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 740px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 744px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ix {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 748px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 752px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_iz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 756px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_i {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 760px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ja {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 764px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jb {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 768px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jc {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 772px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jd {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 776px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_je {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 780px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jf {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 784px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jg {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 788px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jh {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 792px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ji {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 796px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jj {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 800px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jk {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 804px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jl {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 808px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jm {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 812px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jn {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 816px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jo {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 820px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jp {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 824px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jq {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 828px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jr {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 832px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_js {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 836px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jt {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 840px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_ju {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 844px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jv {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 848px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jw {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 852px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jx {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 856px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jy {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 860px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_jz {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 864px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_18_j {
                overflow: visible;
                position: absolute;
                width: 2px;
                height: 1px;
                left: 868px;
                top: 0px;
                transform: matrix(1,0,0,1,0,0);
            }
            #Windu {
                position: absolute;
                left: 93px;
                top: 514px;
                overflow: visible;
                width: 32px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Jasa_Kirim_Paket_Internasional {
                position: absolute;
                left: 105px;
                top: 95px;
                overflow: visible;
                width: 188px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial Rounded MT;
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 14px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #CARRIER {
                position: absolute;
                left: 393px;
                top: 187px;
                overflow: visible;
                width: 50px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__Aramex {
                position: absolute;
                left: 481px;
                top: 187px;
                overflow: visible;
                width: 43px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_21 {
                position: absolute;
                overflow: visible;
                width: 206px;
                height: 73px;
                left: 533px;
                top: 205px;
            }
            #A {
                position: absolute;
                left: 612px;
                top: 205px;
                overflow: visible;
                width: 50px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 73px;
                line-height: 74px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Name_ij {
                position: absolute;
                left: 23px;
                top: 167px;
                overflow: visible;
                width: 30px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__AIRA_B___MAMA_AZMI {
                position: absolute;
                left: 111px;
                top: 167px;
                overflow: visible;
                width: 114px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Phone_ik {
                position: absolute;
                left: 23px;
                top: 187px;
                overflow: visible;
                width: 32px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__081222111346_il {
                position: absolute;
                left: 111px;
                top: 187px;
                overflow: visible;
                width: 77px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Address_im {
                position: absolute;
                left: 23px;
                top: 207px;
                overflow: visible;
                width: 40px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__Cirebon___west_Java___Indone_in {
                position: absolute;
                left: 111px;
                top: 207px;
                overflow: visible;
                width: 240px;
                /* white-space: nowrap; */
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 14px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
                word-wrap: break-word;
            }
            #To__Receiver_ {
                position: absolute;
                left: 48px;
                top: 287px;
                overflow: visible;
                width: 64px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(255,255,255,1);
                letter-spacing: -0.15px;
            }
            .Line_19 {
                overflow: visible;
                position: absolute;
                width: 497.238px;
                height: 1px;
                left: 390.5px;
                top: 552.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_20 {
                overflow: visible;
                position: absolute;
                width: 497.238px;
                height: 1px;
                left: 390.5px;
                top: 565.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_21 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 93px;
                left: 413.5px;
                top: 565.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_22 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 766.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_23 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 93px;
                left: 791.5px;
                top: 565.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            #Name_ij {
                position: absolute;
                left: 23px;
                top: 167px;
                overflow: visible;
                width: 30px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Phone_ik {
                position: absolute;
                left: 23px;
                top: 187px;
                overflow: visible;
                width: 32px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__081222111346_il {
                position: absolute;
                left: 111px;
                top: 187px;
                overflow: visible;
                width: 77px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Address_im {
                position: absolute;
                left: 23px;
                top: 207px;
                overflow: visible;
                width: 40px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__Cirebon___west_Java___Indone_in {
                position: absolute;
                left: 111px;
                top: 207px;
                overflow: visible;
                width: 157px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Name_io {
                position: absolute;
                left: 23px;
                top: 310px;
                overflow: visible;
                width: 30px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__SITI_HAJAR_OMAR {
                position: absolute;
                left: 111px;
                top: 310px;
                overflow: visible;
                width: 100px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Phone_iq {
                position: absolute;
                left: 23px;
                top: 330px;
                overflow: visible;
                width: 32px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__ {
                position: absolute;
                left: 111px;
                top: 330px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Delivery_Address {
                position: absolute;
                left: 23px;
                top: 350px;
                overflow: visible;
                width: 81px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #___it {
                position: absolute;
                left: 111px;
                top: 350px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Post_code {
                position: absolute;
                left: 23px;
                top: 441px;
                overflow: visible;
                width: 49px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__51000 {
                position: absolute;
                left: 111px;
                top: 441px;
                overflow: visible;
                width: 37px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Country {
                position: absolute;
                left: 23px;
                top: 462px;
                overflow: visible;
                width: 39px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #__Malaysia {
                position: absolute;
                left: 111px;
                top: 462px;
                overflow: visible;
                width: 49px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #_60123729577 {
                position: absolute;
                left: 117px;
                top: 330px;
                overflow: visible;
                width: 73px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID36__JALAN_LANAI_KIARA__KIARA {
                position: absolute;
                left: 117px;
                top: 351px;
                overflow: visible;
                width: 240px;
                /* white-space: nowrap; */
                margin-top: -1px;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 14px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.5px;
                word-wrap: break-word;
            }
            #ID1__Jika_barang_di_tolak_oleh {
                position: absolute;
                left: 394px;
                top: 313px;
                overflow: visible;
                width: 437px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID2__Ongkos_Kirim_tidak_termas {
                position: absolute;
                left: 394px;
                top: 323px;
                overflow: visible;
                width: 352px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID3__Customer_Wajib_Memberikan {
                position: absolute;
                left: 394px;
                top: 333px;
                overflow: visible;
                width: 489px;
                height: 22px;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID4__Jika_Detail_barang_yang_d {
                position: absolute;
                left: 394px;
                top: 355px;
                overflow: visible;
                width: 469px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 10px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID5__JASKIPIN_EXPRESS_tidak_be {
                position: absolute;
                left: 394px;
                top: 375px;
                overflow: visible;
                width: 478px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID6__Jika_barang_di_cek_kembal {
                position: absolute;
                left: 394px;
                top: 395px;
                overflow: visible;
                width: 459px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID7__JASKIPIN_EXPRESS_akan_ber {
                position: absolute;
                left: 394px;
                top: 414px;
                overflow: visible;
                width: 457px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID8__Dilarang_mengirim_uang_tu {
                position: absolute;
                left: 394px;
                top: 432px;
                overflow: visible;
                width: 482px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID9__Dilarang_mengirim_uang_tu {
                position: absolute;
                left: 394px;
                top: 452px;
                overflow: visible;
                width: 482px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 8.800000190734863px;
                line-height: 9px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Line_24 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 481.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_25 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 572.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_26 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 618.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_27 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 659.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_28 {
                overflow: visible;
                position: absolute;
                width: 1px;
                height: 49px;
                left: 704.5px;
                top: 503.5px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 584.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_je {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 602.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jf {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 620.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 638.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg2 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 656.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg3 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 674.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg4 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 692.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg5 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 710.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            .Line_29_jg6 {
                overflow: visible;
                position: absolute;
                width: 497.5px;
                height: 1px;
                left: 390.5px;
                top: 728.446px;
                transform: matrix(1,0,0,1,0,0);
            }
            #No__Of_Pieces________________ {
                position: absolute;
                left: 402px;
                top: 510px;
                overflow: visible;
                width: 67px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID1_ji {
                position: absolute;
                left: 432px;
                top: 538px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Actual_weight__Kg_____________ {
                position: absolute;
                left: 484px;
                top: 510px;
                overflow: visible;
                width: 87px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID12_0 {
                position: absolute;
                left: 517px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID1_jl {
                position: absolute;
                left: 399px;
                top: 569px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID2_jm {
                position: absolute;
                left: 399px;
                top: 588px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID3_jn {
                position: absolute;
                left: 399px;
                top: 606px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Kain_Sembagi__20_Set_ {
                position: absolute;
                left: 418px;
                top: 566px;
                line-height:17.5px;
                overflow: visible;
                width: 108px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Kain_Sembagi__20_Set_2 {
                position: absolute;
                left: 397px;
                top: 566px;
                line-height:17.5px;
                overflow: visible;
                width: 108px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #USD {
                position: absolute;
                left: 794px;
                top: 569px;
                overflow: visible;
                width: 24px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID120 {
                position: absolute;
                left: 810px;
                top: 566px;
                line-height:17.5px;
                overflow: visible;
                width: 19px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Chargeable_weight__Kg_________ {
                position: absolute;
                left: 771px;
                top: 510px;
                overflow: visible;
                width: 113px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID12_0_js {
                position: absolute;
                left: 817px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Length___Kg_________________ {
                position: absolute;
                left: 575px;
                top: 504px;
                overflow: visible;
                width: 41px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID20_0 {
                position: absolute;
                left: 584px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #width__Kg_________________ {
                position: absolute;
                left: 621px;
                top: 504px;
                overflow: visible;
                width: 41px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID41_0 {
                position: absolute;
                left: 629px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Height__Kg_________________ {
                position: absolute;
                left: 662px;
                top: 504px;
                overflow: visible;
                width: 41px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID30_0 {
                position: absolute;
                left: 670px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Volumetric__Kg________________ {
                position: absolute;
                left: 711px;
                top: 504px;
                overflow: visible;
                width: 51px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: italic;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID30_0_j {
                position: absolute;
                left: 724px;
                top: 538px;
                overflow: visible;
                width: 21px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            .Rectangle_22 {
                position: absolute;
                overflow: visible;
                width: 496px;
                height: 12px;
                left: 391px;
                top: 553px;
            }
            #No {
                position: absolute;
                left: 395px;
                top: 553px;
                overflow: visible;
                width: 14px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 11px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Description_of_goods {
                position: absolute;
                left: 561px;
                top: 553px;
                overflow: visible;
                width: 98px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 11px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #Custom_Value {
                position: absolute;
                left: 807px;
                top: 553px;
                overflow: visible;
                width: 65px;
                white-space: nowrap;
                text-align: left;
                font-family: Arial;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 11px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID4_j {
                position: absolute;
                left: 399px;
                top: 622px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #ID5_j {
                position: absolute;
                left: 399px;
                top: 642px;
                overflow: visible;
                width: 7px;
                white-space: nowrap;
                text-align: center;
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: rgba(0,0,0,1);
                letter-spacing: -0.15px;
            }
            #MENTAHAN_JASKIPINcdr {
                position: absolute;
                width: 71px;
                height: auto;
                left: 19px;
                top: 60px;
                overflow: visible;
            }`,
    };
  },
  computed: {
    profileData() {
      let profile = JSON.parse(
        window.localStorage.getItem("data-profile-jaskipin")
      );
      if (profile) {
        return profile;
      } else {
        return null;
      }
    },
    totalBerat() {
      const sum = this.data.detail_volume?.reduce((a, { berat }) => {
        return a + berat;
      }, 0);
      return sum;
    },
    totalKoli() {
      return this.data.detail_volume?.reduce((a, { koli }) => {
        return a + koli;
      }, 0);
    },
    volumetric() {
      return this.data.detail_volume?.reduce((a, b) => {
        return a + b.berat >
          ((a + b.panjang) * (a + b.lebar) * (a + b.tinggi)) / 5000
          ? a + b.berat
          : ((a + b.panjang) * (a + b.lebar) * (a + b.tinggi)) / 5000;
      }, 0);
    },
  },
  created() {
    this.getDetailOrder();

    /* Config Print */
    this.d = new Printd();
    const { contentWindow } = this.d.getIFrame();
    contentWindow.addEventListener("beforeprint", () =>
      console.log("before print event!")
    );
    contentWindow.addEventListener("afterprint", () =>
      console.log("after print event!")
    );
  },
  methods: {
    async getDetailOrder() {
      try {
        const response = await axios.get(
          `${api_url}/api/v1/transaction/international-order/admin/get/${this.$route.params.id}`,
          { headers: this.headers }
        );
        console.log(response);

        /* Set Data Selected */
        this.data = response.data.data[0];
        this.getInputBy(this.data.input_by);
        this.getListCourier();

        /* Print Page */
        setTimeout(
          function() {
            this.printPage();
          }.bind(this),
          2000
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getInputBy(id) {
      try {
        const response = await axios.get(
          `${api_url}/api/v1/users/user/get/${id}`,
          { headers: this.headers }
        );
        console.log(response);
        /* Set Data Selected */
        this.input_by = response.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async getListCourier() {
      let query_params = {
        pagination: false,
      };

      try {
        const response = await axios.get(
          `${api_url}/api/v1/shipping-rates/courier/admin`,
          { params: query_params, headers: this.headers }
        );
        console.log(response);
        response.data.map((item) => {
          if (item.courier_type == "International") {
            if (item.courier_name === this.data.courier) {
              this.courierData = item;
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    generateBarcode(value) {
      let JsBarcode = require("jsbarcode");
      JsBarcode("#barcode", value);
    },
    formatDate(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let date = new Date(value).toLocaleString("en-EN", options);
      return date;
    },
    formatPrice(number) {
      var rupiah = "";
      var angkarev = number
        .toString()
        .split("")
        .reverse()
        .join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
    },
    async printPage() {
      await this.d.print(this.$el, [this.cssText]);
    },
  },
};
</script>

<style scoped>
svg#barcode {
  margin: 0px;
  width: 200px;
  margin-top: -50px;
}
.mediaViewInfo {
  --web-view-name: resi;
  --web-view-id: resi;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: resi;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#resi {
  position: absolute;
  width: 923px;
  height: 900px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: resi;
  --web-view-id: resi;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
.Rectangle_1 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 119px;
  left: 19px;
  top: 162px;
}
#signature__name {
  position: absolute;
  left: 93px;
  top: 548px;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#JASKIPIN_COURIER_EXPRESS {
  position: absolute;
  left: 105px;
  top: 71px;
  overflow: visible;
  width: 301px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial Rounded MT;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 22px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#www_Jaskipin_co_id {
  position: absolute;
  left: 105px;
  top: 110px;
  overflow: visible;
  width: 122px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial Rounded MT;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_2 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 24px;
  left: 19px;
  top: 138px;
}
#Shipment_Number {
  position: absolute;
  left: 752px;
  top: 83px;
  overflow: visible;
  width: 136px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#JCRX0000000 {
  position: absolute;
  left: 714px;
  top: 98px;
  overflow: visible;
  width: 155px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Path_2 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 119.062px;
  left: 390.581px;
  top: 162px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_1 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21px;
  left: 44.5px;
  top: 139.198px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID1 {
  position: absolute;
  left: 28px;
  top: 144px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_2 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21px;
  left: 413.671px;
  top: 139.198px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID3 {
  position: absolute;
  left: 399px;
  top: 144px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_3 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21px;
  left: 390.044px;
  top: 139.198px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#From__Sender_ {
  position: absolute;
  left: 48px;
  top: 144px;
  overflow: visible;
  width: 71px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
#CONSIGNMENT_NOTE {
  position: absolute;
  left: 417px;
  top: 144px;
  overflow: visible;
  width: 114px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
#AIRWAYBILL {
  position: absolute;
  left: 393px;
  top: 167px;
  overflow: visible;
  width: 64px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__31532637802 {
  position: absolute;
  left: 481px;
  top: 167px;
  overflow: visible;
  width: 72px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_11 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 24px;
  left: 19px;
  top: 281px;
}
.Line_4 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21.17px;
  left: 44.5px;
  top: 282.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID2 {
  position: absolute;
  left: 28px;
  top: 287px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_5 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21.17px;
  left: 413.671px;
  top: 282.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID4 {
  position: absolute;
  left: 399px;
  top: 287px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_6 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 21.17px;
  left: 390.044px;
  top: 282.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#AUTHORIZATION {
  position: absolute;
  left: 420px;
  top: 287px;
  overflow: visible;
  width: 88px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Rectangle_12 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 172px;
  left: 19px;
  top: 308px;
}
.Path_4 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 172px;
  left: 390.581px;
  top: 308px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Path_8 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 1px;
  left: 685.913px;
  top: 441px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Rectangle_16 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 24px;
  left: 19px;
  top: 480px;
}
.Line_7 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 22px;
  left: 44.5px;
  top: 480.968px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID5 {
  position: absolute;
  left: 28px;
  top: 485px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_8 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 23px;
  left: 413.671px;
  top: 480.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID7 {
  position: absolute;
  left: 399px;
  top: 485px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_9 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 22px;
  left: 390.044px;
  top: 480.968px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Received_By_Courier {
  position: absolute;
  left: 48px;
  top: 485px;
  overflow: visible;
  width: 99px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
#SHIPMENT_INFORMATION {
  position: absolute;
  left: 418px;
  top: 485px;
  overflow: visible;
  width: 134px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Rectangle_17 {
  position: absolute;
  overflow: visible;
  width: 869px;
  height: 156px;
  left: 19px;
  top: 503px;
}
.Path_11 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 155.054px;
  left: 390.581px;
  top: 503.946px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Path_12 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 155.054px;
  left: 90px;
  top: 503.946px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Path_13 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 155.054px;
  left: 237.009px;
  top: 503.946px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_13 {
  overflow: visible;
  position: absolute;
  width: 217px;
  height: 1px;
  left: 19.5px;
  top: 544.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_14 {
  overflow: visible;
  position: absolute;
  width: 371.5px;
  height: 1px;
  left: 19px;
  top: 527.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Path_14 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 23.5px;
  left: 281.41px;
  top: 503.946px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_15 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 22px;
  left: 260.098px;
  top: 480.968px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID6 {
  position: absolute;
  left: 246px;
  top: 485px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_16 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 22px;
  left: 236.472px;
  top: 480.968px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name_______________ {
  position: absolute;
  left: 23px;
  top: 513px;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Date {
  position: absolute;
  left: 23px;
  top: 529px;
  overflow: visible;
  width: 24px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Signature {
  position: absolute;
  left: 23px;
  top: 546px;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID20_11_2019 {
  position: absolute;
  left: 93px;
  top: 529px;
  overflow: visible;
  width: 54px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Name________________bu {
  position: absolute;
  left: 238px;
  top: 513px;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#AIRA_B___MAMA_AZMI____________ {
  position: absolute;
  left: 284px;
  top: 513px;
  overflow: visible;
  width: 147px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Signature__Sender_ {
  position: absolute;
  left: 266px;
  top: 485px;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
#Repeat_Grid_3 {
  position: absolute;
  width: 870px;
  height: 1px;
  left: 19px;
  top: 823px;
  overflow: hidden;
}
#Group_1 {
  position: absolute;
  width: 869px;
  height: 1px;
  left: 0.5px;
  top: 0.5px;
  overflow: visible;
}
.Line_18 {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_b {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 4px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ca {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 8px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 12px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 16px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 20px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ce {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 24px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cf {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 28px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 32px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ch {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 36px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ci {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 40px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 44px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ck {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 48px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 52px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 56px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 60px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_co {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 64px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 68px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 72px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 76px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cs {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 80px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ct {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 84px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 88px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 92px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 96px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 100px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 104px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_cz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 108px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_c {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 112px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_da {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 116px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_db {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 120px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 124px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 128px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_de {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 132px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_df {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 136px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 140px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 144px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_di {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 148px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 152px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dk {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 156px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 160px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 164px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 168px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_do {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 172px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 176px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 180px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 184px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ds {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 188px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dt {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 192px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_du {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 196px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 200px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 204px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 208px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 212px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_dz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 216px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_d {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 220px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ea {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 224px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 228px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ec {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 232px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ed {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 236px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ee {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 240px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ef {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 244px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 248px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 252px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ei {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 256px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ej {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 260px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ek {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 264px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_el {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 268px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_em {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 272px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_en {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 276px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eo {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 280px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ep {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 284px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 288px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_er {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 292px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_es {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 296px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_et {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 300px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_eu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 304px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ev {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 308px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ew {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 312px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ex {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 316px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ey {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 320px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ez {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 324px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_e {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 328px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fa {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 332px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 336px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 340px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 344px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fe {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 348px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ff {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 352px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 356px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 360px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fi {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 364px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 368px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fk {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 372px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 376px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 380px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 384px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fo {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 388px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 392px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 396px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 400px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fs {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 404px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ft {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 408px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 412px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 416px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 420px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 424px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 428px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_fz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 432px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_f {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 436px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ga {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 440px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 444px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 448px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 452px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ge {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 456px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gf {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 460px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 464px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 468px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gi {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 472px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 476px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gk {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 480px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 484px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 488px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 492px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_go {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 496px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 500px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 504px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 508px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gs {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 512px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gt {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 516px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 520px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 524px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 528px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 532px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 536px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_gz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 540px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_g {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 544px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ha {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 548px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 552px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 556px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 560px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_he {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 564px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hf {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 568px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 572px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 576px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hi {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 580px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 584px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hk {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 588px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 592px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 596px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 600px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ho {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 604px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 608px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 612px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 616px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hs {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 620px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ht {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 624px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 628px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 632px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 636px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 640px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 644px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_hz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 648px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_h {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 652px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ia {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 656px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ib {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 660px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ic {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 664px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_id {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 668px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ie {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 672px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_if {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 676px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ig {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 680px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ih {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 684px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ii {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 688px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ij {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 692px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ik {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 696px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_il {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 700px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_im {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 704px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_in {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 708px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_io {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 712px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ip {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 716px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 720px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ir {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 724px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_is {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 728px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_it {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 732px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iu {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 736px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 740px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 744px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ix {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 748px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 752px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_iz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 756px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_i {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 760px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ja {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 764px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jb {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 768px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jc {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 772px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jd {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 776px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_je {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 780px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jf {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 784px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jg {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 788px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jh {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 792px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ji {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 796px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jj {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 800px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jk {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 804px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jl {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 808px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jm {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 812px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jn {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 816px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jo {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 820px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jp {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 824px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jq {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 828px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jr {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 832px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_js {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 836px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jt {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 840px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_ju {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 844px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jv {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 848px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jw {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 852px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jx {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 856px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jy {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 860px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_jz {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 864px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_18_j {
  overflow: visible;
  position: absolute;
  width: 2px;
  height: 1px;
  left: 868px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Windu {
  position: absolute;
  left: 93px;
  top: 514px;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Jasa_Kirim_Paket_Internasional {
  position: absolute;
  left: 105px;
  top: 95px;
  overflow: visible;
  width: 188px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial Rounded MT;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#CARRIER {
  position: absolute;
  left: 393px;
  top: 187px;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__Aramex {
  position: absolute;
  left: 481px;
  top: 187px;
  overflow: visible;
  width: 43px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_21 {
  position: absolute;
  overflow: visible;
  width: 206px;
  height: 73px;
  left: 533px;
  top: 205px;
}
#A {
  position: absolute;
  left: 612px;
  top: 205px;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 73px;
  line-height: 74px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Name_ij {
  position: absolute;
  left: 23px;
  top: 167px;
  overflow: visible;
  width: 30px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__AIRA_B___MAMA_AZMI {
  position: absolute;
  left: 111px;
  top: 167px;
  overflow: visible;
  width: 114px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Phone_ik {
  position: absolute;
  left: 23px;
  top: 187px;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__081222111346_il {
  position: absolute;
  left: 111px;
  top: 187px;
  overflow: visible;
  width: 77px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_im {
  position: absolute;
  left: 23px;
  top: 207px;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__Cirebon___west_Java___Indone_in {
  position: absolute;
  left: 111px;
  top: 207px;
  overflow: visible;
  width: 240px;
  /* white-space: nowrap; */
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
  word-wrap: break-word;
}
#To__Receiver_ {
  position: absolute;
  left: 48px;
  top: 287px;
  overflow: visible;
  width: 64px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.15px;
}
.Line_19 {
  overflow: visible;
  position: absolute;
  width: 497.238px;
  height: 1px;
  left: 390.5px;
  top: 552.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_20 {
  overflow: visible;
  position: absolute;
  width: 497.238px;
  height: 1px;
  left: 390.5px;
  top: 565.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_21 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 93px;
  left: 413.5px;
  top: 565.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_22 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 766.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_23 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 93px;
  left: 791.5px;
  top: 565.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Name_ij {
  position: absolute;
  left: 23px;
  top: 167px;
  overflow: visible;
  width: 30px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Phone_ik {
  position: absolute;
  left: 23px;
  top: 187px;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__081222111346_il {
  position: absolute;
  left: 111px;
  top: 187px;
  overflow: visible;
  width: 77px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Address_im {
  position: absolute;
  left: 23px;
  top: 207px;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__Cirebon___west_Java___Indone_in {
  position: absolute;
  left: 111px;
  top: 207px;
  overflow: visible;
  width: 157px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Name_io {
  position: absolute;
  left: 23px;
  top: 310px;
  overflow: visible;
  width: 30px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__SITI_HAJAR_OMAR {
  position: absolute;
  left: 111px;
  top: 310px;
  overflow: visible;
  width: 100px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Phone_iq {
  position: absolute;
  left: 23px;
  top: 330px;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__ {
  position: absolute;
  left: 111px;
  top: 330px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Delivery_Address {
  position: absolute;
  left: 23px;
  top: 350px;
  overflow: visible;
  width: 81px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#___it {
  position: absolute;
  left: 111px;
  top: 350px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Post_code {
  position: absolute;
  left: 23px;
  top: 441px;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__51000 {
  position: absolute;
  left: 111px;
  top: 441px;
  overflow: visible;
  width: 37px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Country {
  position: absolute;
  left: 23px;
  top: 462px;
  overflow: visible;
  width: 39px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#__Malaysia {
  position: absolute;
  left: 111px;
  top: 462px;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#_60123729577 {
  position: absolute;
  left: 117px;
  top: 330px;
  overflow: visible;
  width: 73px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID36__JALAN_LANAI_KIARA__KIARA {
  position: absolute;
  left: 117px;
  top: 351px;
  overflow: visible;
  width: 240px;
  /* white-space: nowrap; */
  margin-top: -1px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.5px;
  word-wrap: break-word;
}
#ID1__Jika_barang_di_tolak_oleh {
  position: absolute;
  left: 394px;
  top: 313px;
  overflow: visible;
  width: 437px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID2__Ongkos_Kirim_tidak_termas {
  position: absolute;
  left: 394px;
  top: 323px;
  overflow: visible;
  width: 352px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID3__Customer_Wajib_Memberikan {
  position: absolute;
  left: 394px;
  top: 333px;
  overflow: visible;
  width: 489px;
  height: 22px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID4__Jika_Detail_barang_yang_d {
  position: absolute;
  left: 394px;
  top: 355px;
  overflow: visible;
  width: 469px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 10px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID5__JASKIPIN_EXPRESS_tidak_be {
  position: absolute;
  left: 394px;
  top: 375px;
  overflow: visible;
  width: 478px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID6__Jika_barang_di_cek_kembal {
  position: absolute;
  left: 394px;
  top: 395px;
  overflow: visible;
  width: 459px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID7__JASKIPIN_EXPRESS_akan_ber {
  position: absolute;
  left: 394px;
  top: 414px;
  overflow: visible;
  width: 457px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID8__Dilarang_mengirim_uang_tu {
  position: absolute;
  left: 394px;
  top: 432px;
  overflow: visible;
  width: 482px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID9__Dilarang_mengirim_uang_tu {
  position: absolute;
  left: 394px;
  top: 452px;
  overflow: visible;
  width: 482px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8.800000190734863px;
  line-height: 9px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Line_24 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 481.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_25 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 572.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_26 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 618.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_27 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 659.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_28 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 49px;
  left: 704.5px;
  top: 503.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 584.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_je {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 602.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jf {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 620.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 638.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg2 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 656.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg3 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 674.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg4 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 692.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg5 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 710.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.Line_29_jg6 {
  overflow: visible;
  position: absolute;
  width: 497.5px;
  height: 1px;
  left: 390.5px;
  top: 728.446px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#No__Of_Pieces________________ {
  position: absolute;
  left: 402px;
  top: 510px;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID1_ji {
  position: absolute;
  left: 432px;
  top: 538px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Actual_weight__Kg_____________ {
  position: absolute;
  left: 484px;
  top: 510px;
  overflow: visible;
  width: 87px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID12_0 {
  position: absolute;
  left: 517px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID1_jl {
  position: absolute;
  left: 399px;
  top: 569px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID2_jm {
  position: absolute;
  left: 399px;
  top: 588px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID3_jn {
  position: absolute;
  left: 399px;
  top: 606px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Kain_Sembagi__20_Set_ {
  position: absolute;
  left: 418px;
  top: 566px;
  line-height: 17.5px;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Kain_Sembagi__20_Set_2 {
  position: absolute;
  left: 397px;
  top: 566px;
  line-height: 17.5px;
  overflow: visible;
  width: 108px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#USD {
  position: absolute;
  left: 794px;
  top: 569px;
  overflow: visible;
  width: 24px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID120 {
  position: absolute;
  left: 810px;
  top: 566px;
  line-height: 17.5px;
  overflow: visible;
  width: 19px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Chargeable_weight__Kg_________ {
  position: absolute;
  left: 771px;
  top: 510px;
  overflow: visible;
  width: 113px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID12_0_js {
  position: absolute;
  left: 817px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Length___Kg_________________ {
  position: absolute;
  left: 575px;
  top: 504px;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID20_0 {
  position: absolute;
  left: 584px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#width__Kg_________________ {
  position: absolute;
  left: 621px;
  top: 504px;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID41_0 {
  position: absolute;
  left: 629px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Height__Kg_________________ {
  position: absolute;
  left: 662px;
  top: 504px;
  overflow: visible;
  width: 41px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID30_0 {
  position: absolute;
  left: 670px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Volumetric__Kg________________ {
  position: absolute;
  left: 711px;
  top: 504px;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID30_0_j {
  position: absolute;
  left: 724px;
  top: 538px;
  overflow: visible;
  width: 21px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
.Rectangle_22 {
  position: absolute;
  overflow: visible;
  width: 496px;
  height: 12px;
  left: 391px;
  top: 553px;
}
#No {
  position: absolute;
  left: 395px;
  top: 553px;
  overflow: visible;
  width: 14px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Description_of_goods {
  position: absolute;
  left: 561px;
  top: 553px;
  overflow: visible;
  width: 98px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#Custom_Value {
  position: absolute;
  left: 807px;
  top: 553px;
  overflow: visible;
  width: 65px;
  white-space: nowrap;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID4_j {
  position: absolute;
  left: 399px;
  top: 622px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#ID5_j {
  position: absolute;
  left: 399px;
  top: 642px;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.15px;
}
#MENTAHAN_JASKIPINcdr {
  position: absolute;
  width: 71px;
  height: auto;
  left: 19px;
  top: 60px;
  overflow: visible;
}
</style>
